import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { a, useTrail, config, useSpring, useInView } from "@react-spring/web";
import "../../styles/App.css";
import LetterMatrixAnimation from "../shared/matrix_animation";
import WaterWaves from "../shared/wave";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import "../../styles/toPortfolio.css";
import DirectionComponent from "../shared/curly_direction";
import RotateAnimation from "../shared/rotate_animation";

const ToPortfolio = ({ data }) => {
  const [isHovering, setIsHovering] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const handleMouseEnter = () => setIsHovering(true);

  const handleMouseLeave = () => setIsHovering(false);

  // Function to handle the previous button click
  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? data.length - 1 : prevIndex - 1
    );
  };
  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % data.length);
  };

  const [overlayAnimation, setOverlayAnimation] = useSpring(() => ({
    from: { top: "0%" },
    to: { top: "100%" },
    config: { duration: 1000 },
  }));

  useEffect(() => {
    // Update the animation when currentIndex changes
    setOverlayAnimation({
      to: { top: "0%" },
      from: { top: "100%" },
    });
  }, [setOverlayAnimation, currentIndex]);

  const currentImageStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
  };

  const previousImageStyle = {
    ...currentImageStyle,
    opacity: 0.5,
    zIndex: -1,
  };

  const groupSize = 4;

  const textWords = data[currentIndex].description.split(" ");
  const groupedText = [];
  for (let i = 0; i < textWords.length; i += groupSize) {
    groupedText.push(textWords.slice(i, i + groupSize).join(" "));
  }

  const [refSlowOp, viewSlowOp] = useInView({
    rootMargin: "0% 0%",
    once: true,
  });

  const [refSlideUpDescription, slideUpDescription] = useInView(
    () => ({
      from: {
        transform: "translateY(10px)",
        transformOrigin: "50% 100%",
        opacity: 0,
      },
      to: {
        transform: "translateY(0)",
        transformOrigin: "50% 50%",
        opacity: 1,
      },
      delay: 400,
      config: { tension: 100, friction: 500, duration: 500 },
    }),
    { rootMargin: "0% 0%", once: true }
  );

  const slowOpDescription = useTrail(groupedText.length, {
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { tension: 100, friction: 500, duration: 300 },
    reset: viewSlowOp,
  });

  const moveDown = useSpring({
    from: { opacity: 1 },
    to: { opacity: isHovering ? 0 : 1 },
  });

  return (
    <>
      <div className="md:hidden center-div flex-col my-16">
        {/* line */}
        <div className="relative top-[0.25rem] border-4 border-dark-green h-4 w-4 rounded-3xl bg-white"></div>
        <div className="relative z-10 w-0.5 h-14 border-l-2 bg-gray-green"></div>
        <div className="relative -top-[0.25rem] border-4 border-dark-green h-4 w-4 rounded-3xl bg-white"></div>
      </div>
      <div className="relative flex flex-row justify-center col-divider mt-12">
        <div className="flex flex-col text-light-green text-xs md:text-base">
          <p className="text-gray flex flex-row relative right-10">
            <LetterMatrixAnimation word="<h2>" />
          </p>
          <p className="title text-white">
            <RotateAnimation word="My Portfolio" />
          </p>
          <p className="text-gray flex flex-row-reverse relative lef  t-10 sm:-mt-3 lg:-mt-6">
            <LetterMatrixAnimation word="<h2>" />
          </p>
        </div>
      </div>
      <div>
        <a.div style={moveDown} className="flex justify-center text-green">
          Click image to see detailed portfolio
        </a.div>
      </div>
      {/*PORTFOLIO*/}
      <Link
        to={{
          pathname: `/portfolio/${currentIndex}`,
        }}
      >
        <div className="flex flex-col md:flex-row justify-center items-center w-full mb-10">
          <div className="relative mt-5">
            <div
              className="relative"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <div className="relative w-52 h-52 md:w-72 md:h-72 lg:w-80 lg:h-80 rounded-[50%] overflow-hidden z-40">
                <div className="absolute top-0 left-0 w-full h-full flex items-center content-center">
                  {/*//Todo:Implement_water_wave_if_its_worth_it!*/}
                  <a.img
                    src={data[currentIndex % data.length]?.image}
                    alt={`${currentIndex + 1}`}
                    style={{
                      ...currentImageStyle,
                      top: overlayAnimation.top,
                      zIndex: 30,
                    }}
                  />
                  {/*Current image */}
                  <img
                    src={data[(currentIndex - 1) % data.length]?.image}
                    alt={`${currentIndex}`}
                    style={{
                      ...currentImageStyle,
                      zIndex: 20,
                    }}
                  />
                  {/* Previous image */}
                  <img
                    src={
                      data[
                        currentIndex === 0 ? data.length - 1 : currentIndex - 1
                      ]?.image
                    }
                    alt={`${currentIndex - 1}`}
                    style={{
                      ...previousImageStyle,
                      zIndex: 10,
                    }}
                  />
                </div>
              </div>
              <WaterWaves isHovering={isHovering} />
            </div>
          </div>
          <div className="text-light-green w-9/12 md:w-1/2 lg:w-1/3 text-xs md:text-base">
            <p className="text-gray flex flex-row mr-20">
              <LetterMatrixAnimation word="<p>" />
            </p>
            <div className="ml-7 md:ml-14">
              {/*title*/}
              <p className="font-larabie text-base md:text-lg lg:text-2xl">
                <LetterMatrixAnimation word={data[currentIndex].project} />
              </p>
              {/*description*/}
              <div className="description text-white">
                <a.p
                  style={slideUpDescription}
                  className="description w-3/4"
                  ref={refSlideUpDescription}
                >
                  {slowOpDescription.map((props, index) => (
                    <a.span key={index} style={props} ref={refSlowOp}>
                      {groupedText[index]}{" "}
                    </a.span>
                  ))}
                </a.p>
              </div>
              {/*occupation*/}
              <p className="uppercase  text-1xs sm:text-sm md:text-lg lg:text-2xl">
                <LetterMatrixAnimation word={data[currentIndex].position} />
              </p>
            </div>
            <p className="text-gray flex flex-row">
              <LetterMatrixAnimation word="<p>" />
            </p>
          </div>
        </div>
      </Link>
      <div className="flex flex-col justify-center items-center">
        <div className="center-div w-full mt-24">
          <div onClick={handlePrevious}>
            <SliderArrows direction={"left"}>
              <FontAwesomeIcon icon={faChevronLeft} size="lg" />
            </SliderArrows>
          </div>
          <progress
            value={currentIndex + 1}
            max={data.length}
            className="bg-dark-green w-[40%] h-2 mx-8 md:mx-16"
          ></progress>
          <div onClick={handleNext}>
            <SliderArrows direction={"right"}>
              <FontAwesomeIcon icon={faChevronRight} size="lg" />
            </SliderArrows>
          </div>
        </div>
        <DirectionComponent />
      </div>
    </>
  );
};

export default ToPortfolio;

const SliderArrows = (props) => {
  const [arrowHoverLeft, setArrowHoverLeft] = useState(false);
  const [arrowHoverRight, setArrowHoverRight] = useState(false);

  const arrowEnteringLeft = () => setArrowHoverLeft(true);

  const arrowLeavingLeft = () => setArrowHoverLeft(false);

  const arrowEnteringRight = () => setArrowHoverRight(true);

  const arrowLeavingRight = () => setArrowHoverRight(false);

  const hoverScaleLeft = useSpring({
    from: { transform: "scale(1)" },
    to: { transform: arrowHoverLeft ? "scale(1.5)" : "scale(1)" },
    config: config.slow,
  });

  const hoverScaleRight = useSpring({
    from: { transform: "scale(1)" },
    to: { transform: arrowHoverRight ? "scale(1.5)" : "scale(1)" },
    config: config.slow,
  });

  return (
    <a.div
      onMouseEnter={
        props.direction === "left" ? arrowEnteringLeft : arrowEnteringRight
      }
      onMouseLeave={
        props.direction === "left" ? arrowLeavingLeft : arrowLeavingRight
      }
      style={props.direction === "left" ? hoverScaleLeft : hoverScaleRight}
      className="relative w-8 h-8 md:w-16 md:h-16 border border-white rounded-[50%] center-div cursor-pointer z-50"
    >
      {props.children}
    </a.div>
  );
};
