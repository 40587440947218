import React, { useEffect, useRef, useState } from "react";
import { useSpring, a, config, useSprings } from "@react-spring/web";
import LetterMatrixAnimation from "../shared/matrix_animation";

const commonSpringConfig = {
  from: {
    transform: "rotateX(90deg) rotateZ(3deg)",
    transformOrigin: "50% 0%",
  },
  to: {
    transform: "rotateX(0deg) rotateZ(0deg)",
    transformOrigin: "50% 100%",
  },
  config: { tension: 280, friction: 120, duration: 2000 },
};

const delayValues = [0, 400];

const WhoAmI = ({ who }) => {
  let data = who[0];

  let author = data.name;
  const [isHovered, setIsHovered] = useState(false);

  const rotate_1 = useSprings(
    author.length,
    author.map((title, index) => ({
      ...commonSpringConfig,
      delay: delayValues[index], // Apply delay based on index
    }))
  );

  // useChain(inView ? [rotateOne, rotateTwo] : [],inView?  [0, 0.4]: []);

  const mouseEnter = () => {
    setIsHovered(true);
  };

  const mouseLeave = () => {
    setIsHovered(false);
  };

  const cvBackground = useSpring({
    from: { backgroundColor: "transparent", transform: "scale(0)" },
    to: {
      backgroundColor: isHovered ? "#00ff94" : "transparent",
      transform: isHovered ? "scale(1)" : "scale(0)",
    },
    config: config.molasses,
  });

  const cvText = useSpring({
    from: { color: "#00ff94" },
    to: { color: isHovered ? "white" : "#00ff94" },
  });

  const scaleUp = useSpring({
    from: { scale: 0 },
    to: { scale: 1 },
    delay: 500,
    config: config.molasses,
  });

  const ref = useRef(null);
  const ref_ = useRef(null);

  const [heightPointer, animateHeight] = useSpring(
    () => ({
      height: "0px",
      transformOrigin: "left", // Set the transform origin to the left
      config: config.molasses,
    }),
    []
  );

  useEffect(() => {
    animateHeight({
      height: ref_.current.offsetHeight + "px",
      delay: 750,
    });
  }, [animateHeight, ref_]);

  const pointerWidth = useSpring({
    from: { width: "0%", opacity: 0 }, // Start with 0 height
    to: { width: "100%", opacity: 1 }, // End with 100% height
    delay: 500,
    config: config.slow, // Adjust the duration as needed
  });

  const pointerWidth_ = useSpring({
    from: { width: "0%", opacity: 0 }, // Start with 0 height
    to: { width: "100%", opacity: 1 }, // End with 100% height
    delay: 750,
    config: config.slow, // Adjust the duration as needed
  });

  const pointerBall = useSpring({
    from: { opacity: 0, transform: 0 }, // Start with 0 height
    to: [{ opacity: 1, transform: 1.5 }, { transform: 1 }], // End with 100% height
    delay: 1500,
    config: { duration: 500 }, // Adjust the duration as needed
  });

  // Replace 'your-file-path' with the actual path to your file on the server
  const filePath = data.cv_file;

  const handleDownload = () => {
    // Create a link element
    const link = document.createElement("a");

    // Set the href attribute to the file path
    link.href = filePath;

    // Set the download attribute with the desired file name
    link.download = "my_cv.pdf";

    // Append the link to the document
    document.body.appendChild(link);

    // Trigger a click on the link to start the download
    link.click();

    // Remove the link from the document after the download is initiated
    document.body.removeChild(link);
  };

  return (
    <div className="flex justify-around col-divider">
      <div className="w-11/12">
        <div className="">
          <div className="center-div flex-col gap-4 md:gap-0 md:items-stretch md:flex-row md:justify-around">
            {/*Name of owner*/}
            <div className="flex flex-col justify-evenly text-xs md:text-base">
              <div className="flex flex-col justify-around">
                <div className="flex flex-col justify-around">
                  <div className="description text-gray">
                    <LetterMatrixAnimation
                      word="<p>My Name Is</p>"
                      className="text-green"
                    />
                  </div>
                </div>

                <div className="ml-12 my-10 flex flex-row flex-grow">
                  <div className="text-gray">
                    <LetterMatrixAnimation word="<h1>" />
                    {rotate_1.map((style, index) => (
                      <div key={index}>
                        <a.p
                          style={style}
                          className="ml-4 text-white text-lg sm:text-4xl lg:text-6xl font-larabie font-normal flex gap-4"
                        >
                          {author[index]}
                        </a.p>
                        {index === 0 ? <br /> : ""}
                      </div>
                    ))}
                    <LetterMatrixAnimation word="</h1>" />
                  </div>
                </div>

                <div className="flex flex-col justify-around">
                  <div className="description text-gray">
                    <LetterMatrixAnimation
                      word={`<p>${data.position}</p>`}
                      className="text-green"
                    />
                  </div>
                </div>
              </div>
              <div className="hidden lg:block relative right-8 -top-[28%] text-light-green">
                <a.div
                  style={{
                    transform: pointerBall.transform.to(
                      (transform) => `scale(${transform})`
                    ),
                  }}
                  className="relative z-10 top-3 left-10 border-4 border-transparent-green center-div h-5 w-5 rounded-3xl"
                >
                  <div className="border-transparent-green h-3 w-3 rounded-3xl bg-white"></div>
                </a.div>
                {/*Line*/}
                <div className="w-12 h-0 border-l-2 border-b-2 bg-gray-green"></div>
                <div className="flex flex-row justify-start" ref={ref}>
                  {/* curve */}
                  <a.div
                    style={pointerWidth}
                    className="h-60 w-full border-l-2 border-b-2 rounded-bl-3xl"
                  >
                    <span className="relative left-[60%] top-56 text-white text-2xl">
                      &lt;/&gt;
                    </span>
                  </a.div>
                  {/*  curve 2  */}
                  <div className="w-1/2 h-32" ref={ref_}>
                    <a.div
                      style={{ ...heightPointer, ...pointerWidth_ }}
                      className="relative top-[14.9rem] w-[150%] border-t-2 border-r-2 rounded-tr-3xl"
                    ></a.div>
                  </div>
                </div>
                <a.div
                  style={{
                    transform: pointerBall.transform.to(
                      (transform) => `scale(${transform})`
                    ),
                  }}
                  className="relative z-10 top-[43%]
                                            left-[97.5%] border-4
                                            border-transparent-green
                                            center-div
                                            h-5 w-5 rounded-3xl"
                >
                  <div className="border-transparent-green h-3 w-3 rounded-3xl bg-white"></div>
                </a.div>
              </div>
            </div>
            {/*Download CV*/}
            <a.div
              style={scaleUp}
              className="w-44 h-44 md:w-72 md:h-72 border-0"
            >
              <div className="relative shape-full z-50 center-div">
                <div
                  className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 opacity-5
                                        scale-y-[-1] rotate-[135deg] font-larabie text-6xl md:text-[7rem] z-0"
                >
                  &lt;/&gt;
                </div>

                <div className="clips shape-full center-div z-10">
                  <div className="absolute shape-full top-[2%] left-[5%]">
                    <div className="white-circle left-[75%] top-[14%] z-10"></div>
                    <div className="white-arc w-[90%] h-[90%] rotate-[90deg] z-0"></div>
                    <div className="white-circle left-[75%] bottom-[14%]"></div>
                  </div>
                </div>

                <div className="clips-2 shape-full center-div z-20">
                  <div className="absolute shape-full top-[7%] left-[10%]">
                    <div className="white-circle top-[13%] left-[67%] z-10"></div>
                    <div className="white-arc w-[80%] h-[80%] rotate-[90deg] z-0"></div>
                    <div className="white-circle left-[67%] bottom-[13%]"></div>
                  </div>
                </div>

                <div className="clips-3 shape-full center-div z-20">
                  <div className="absolute shape-full top-[12%] left-[15%]">
                    <div className="white-circle left-[25%] top-[3%] z-10"></div>
                    <div className="white-arc w-[70%] h-[70%] rotate-[300deg] z-0"></div>
                    <div className="white-circle left-[0%] bottom-[26%]"></div>
                  </div>
                </div>

                <div className="center-div">
                  <a.div
                    onMouseEnter={mouseEnter}
                    onMouseLeave={mouseLeave}
                    style={{
                      backgroundColor: cvBackground.backgroundColor,
                      transform: cvBackground.transform,
                    }}
                    className="absolute center-div flex-col rounded-[999px] w-[55%] h-[55%] z-30 cursor-pointer"
                  ></a.div>
                  <div className="center-div flex-col absolute rounded-[999px] border border-white w-[55%] h-[55%] z-30">
                    <a.div
                      onMouseEnter={mouseEnter}
                      onMouseLeave={mouseLeave}
                      style={{ color: cvText.color }}
                      onClick={handleDownload}
                      className="text-green text-1xs md:text-base cursor-pointer"
                    >
                      &lt;Download CV/&gt;
                    </a.div>
                  </div>
                </div>
              </div>
            </a.div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhoAmI;
