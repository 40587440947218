import React, { useEffect, useState } from 'react';
import {a, useInView} from '@react-spring/web';

const getRandomCharacter = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    const randomIndex = Math.floor(Math.random() * characters.length);
    return characters[randomIndex];
};

const MatrixAnimation = (props) => {
    const [word, setWord] = useState(props.word)
    const [text, setText] = useState('');
    const [animationIndex, setAnimationIndex] = useState(0);
    const [currentLetter, setCurrentLetter] = useState(getRandomCharacter);
    const [animationCount, setAnimationCount] = useState(3);

    useEffect(() => {
        setWord(props.word);
        setText('');
        setAnimationIndex(0);
        setCurrentLetter(getRandomCharacter);
        setAnimationCount(3);
    }, [props.word]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (animationIndex < word.length) {
                for (let i = 0; i < animationCount; i++) {
                    setCurrentLetter(getRandomCharacter);
                    setAnimationCount(animationCount - 1);
                }
                setAnimationCount(3);
                setAnimationIndex((prevIndex) => prevIndex + 1);
                setText((prevText) => prevText + word[prevText.length]);
            }
        }, 100); // Change the letter every 100 microseconds

        return () => {
            clearInterval(interval);
        };
    }, [text, word, animationCount, animationIndex]);

    const grayStyle = {
        color: 'gray',
    };

    const endAndReset = () => {
        return ''
    }
    return (
        <>
            {text.split('').map((prop, index) => {
                if (props.word.indexOf('<') <= index && word.indexOf('>') >= index && word.includes('</p>')) {
                    return (
                        <a.span key={index} style={grayStyle}>
                            {prop}
                        </a.span>
                    );
                } else if (props.word.lastIndexOf('<') <= index && word.lastIndexOf('>') >= index && word.includes('</p>')) {
                    return (
                        <a.span key={index} style={grayStyle}>
                            {prop}
                        </a.span>
                    );
                } else {
                    // Regular text
                    return (
                        <a.span key={index}>
                            {prop}
                        </a.span>
                    );
                }
            })}
            {text.length === word.length ? endAndReset() : currentLetter}
        </>
    )
}

const LetterMatrixAnimation = (props) => {

    const [ref, inView] = useInView({
        triggerOnce: true, // Trigger the animation only once
        rootMargin: '0% 50%'
    });

    return (
        <span className={props.className}  ref={ref}>
           {inView && <MatrixAnimation word={props.word} />}
        </span>
    );
};

export default LetterMatrixAnimation;

