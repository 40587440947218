import React from 'react';
import {useSpring, a} from '@react-spring/web';
import '../../styles/App.css'

const WaterWaves = ({isHovering}) => {
    const waves = [0, 1, 2, 3]; // You can add more waves as needed

    return (
        <div className="static z-20">
            {waves.map((index) => (
                <WaterWave key={index} delay={index * 500} isAnimating={isHovering}/>
            ))}
        </div>
    );
};

const WaterWave = ({delay, isAnimating}) => {

    const scaleUp = useSpring({
        opacity: 0,
        from: {transform: 'scale(0.9)', opacity: 0, display: 'block'},
        to: async (next) => {
            if (isAnimating) {
                await next({transform: 'scale(1.5)', opacity: 1, display: 'block'})
                await next({transform: 'scale(0.9)', opacity: 1, display: 'none'})
                await next({transform: 'scale(0.9)', opacity: 1, display: 'block'})
            } else {
                await next({transform: 'scale(0.9)', opacity: 1, display: 'none'})
                await next({transform: 'scale(0.9)', opacity: 1, display: 'none'})
                await next({transform: 'scale(0.9)', opacity: 1, display: 'block'})
            }
        },
        delay,
        config: {duration: 2500}
    });

    return <a.div style={scaleUp}
                  className="absolute z-0 top-0 left-0 w-full h-full border-2 border-white rounded-[50%]">
    </a.div>;
};

export default WaterWaves;
