import React, { useState} from 'react';
import {useSpring, a, config} from "@react-spring/web";
import LetterMatrixAnimation from "../shared/matrix_animation";
import RotateAnimation from "../shared/rotate_animation";
import '../../styles/App.css';

const arrowWhite = "image/arrow-white.png";

const BlogItem = ({itemNum, blogItem, dataLength}) => {

    const [isHovered, setIsHovered] = useState(false);

    const bgAnimate = useSpring({
        from: {height: '0%'},
        to: {height: isHovered ? '100%' : '0%'}
    });

    const titleAnimate = useSpring({
        from: { color: 'white', fontSize: '230%', top: '10px' },
        to: {
            color: isHovered ? 'black' : 'white',
            fontSize: isHovered ? '230%' : '250%',
            top: isHovered ? '2px' : '10px'
        }, config: config.molasses
    });

    const props_ = useSpring({
        from: {opacity: 1, transform: 'translateX(0px)'},
        to: async (next) => {
            if (isHovered) {
                while (isHovered) {
                    await next({opacity: 0.999, transform: 'translateX(0px)'});
                    await next({opacity: 1, transform: 'translateX(20px)'});
                }
            } else {
                await next({opacity: 0.999, transform: 'translateX(0px)'});
            }
        },
        onRest: () => {
            setIsHovered(false);
        }
    });

    const dateAnimate = useSpring({
        from: {opacity: 0},
        to: {opacity: isHovered ? 1 : 0}
    });

    const mouseEnter = () => {
        setIsHovered(true);
    };

    const mouseLeave = () => {
        setIsHovered(false);
    };

    const handleClick = () => {
        if (blogItem.link) {
            window.location.href = blogItem.link;
        }
    };

    return (
        <div
            onMouseEnter={mouseEnter}
            onMouseLeave={mouseLeave}
            className="relative"
            onClick={handleClick}
        >
            <a.div style={bgAnimate} className="absolute bg-green w-full bottom-0"></a.div>
            <div className={`relative flex justify-between items-center border-light-green border-t-2 py-3 md:py-7 px-4 
            md:px-14 lg:px-28 z-10  ${itemNum === dataLength - 1 ? 'border-b-2' : ''}`}>
                <div>
                    <div className="text-1xs sm:text-sm md:text-base lg:text-lg">
                        <a.p style={titleAnimate} className="blog-title relative">
                            <LetterMatrixAnimation word={blogItem.title} />
                        </a.p>
                    </div>
                    <a.p style={dateAnimate} className="text-black">{blogItem.date}</a.p>
                </div>
                <div>
                    <a.div style={{ filter: isHovered ? 'invert(100)' : 'invert(0)', ...props_ }}>
                        <img src={arrowWhite} alt="" className="text-1xs sm:text-sm md:text-base lg:text-lg"/>
                    </a.div>
                </div>
            </div>
        </div>
    );
};

const Blog = ({data}) => {
    return (
        <div>
            <div className="relative md:-mt-48 mb-16 flex flex-row justify-center">
                <div className="flex flex-col text-light-green  text-xs md:text-base">
                    <p className="text-gray flex flex-row relative right-10"><LetterMatrixAnimation word="<h3>"/></p>
                    <p className="title text-white"><RotateAnimation word="My Blog"/></p>
                    <p className="text-gray flex flex-row-reverse relative left-10 sm:-mt-3 lg:-mt-6">
                        <LetterMatrixAnimation word="</h3>"/></p>
                </div>
            </div>
            <div className="relative font-extrabold w-full cursor-pointer">
                {data.map((blogItem, index) => (
                    <BlogItem key={index} itemNum={index} blogItem={blogItem} dataLength={data.length}/>
                ))}
            </div>
        </div>
    );
};

export default Blog;
