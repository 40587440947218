import React from 'react';
import {a, useInView, useTrail} from '@react-spring/web';

const Rotate = ({word}) => {

    const groupSize = 1;

    const textWords = word.split('');
    const groupedText = [];
    for (let i = 0; i < textWords.length; i += groupSize) {
        groupedText.push(textWords.slice(i, i + groupSize).join(' '));
    }

    const slowOpDescription = useTrail(groupedText.length, {
        from: {opacity: 0, transform: 'rotate(-45deg) scale(0)'},
        to: {opacity: 1, transform: 'rotate(0) scale(1)'},
        config: {duration: 100, tension: 280, friction: 120}
    });

    return (
        <span className="whitespace-pre">
            {slowOpDescription.map((props, index) => (
                <a.span key={index} style={props} className="letter inline-block">
                    {groupedText[index]}
                </a.span>
            ))}
        </span>
    );
};

const RotateAnimation = (props) => {

    const [ref, inView] = useInView({
        triggerOnce: true, // Trigger the animation only once
        rootMargin: '0% 50%'
    });

    return (
        <span ref={ref}>
            {inView && <Rotate word={props.word} />}
        </span>
    );
};

export default RotateAnimation;
