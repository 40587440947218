import React from 'react';
import { useTrail, config, a, useInView} from '@react-spring/web'

function VisualIdentification(props) {



    const [refSlideUpWrapper, slideUpWrapper] = useInView(() => ({
        from: {
            transform: 'translateY(100px)',
            opacity: 0,
        },
        to: {
            transform: 'translateY(0)',
            opacity: 1,
        },
        config: config.molasses
    }), {rootMargin: '0% 0%', once: true});

    const [refSlideUpTitle, slideUpTitle] = useInView(()=> ({
        from: {
            transform: 'translateY(100px)',
            transformOrigin: '50% 100%',
            opacity: 0,
        },
        to: {
            transform: 'translateY(0)',
            transformOrigin: '50% 50%',
            opacity: 1,
        },
        config: {tension: 100, friction: 100, duration: 500}
    }), {rootMargin: '0% 0%', once: true});

    const [refSlideUpTitle_, slideUpTitle_] = useInView(() => ({
        from: {
            transform: 'translateY(100px)',
            transformOrigin: '50% 100%',
            opacity: 0,
        },
        to: {
            transform: 'translateY(0)',
            transformOrigin: '50% 50%',
            opacity: 1,
        },
        delay: 200,
        config: {tension: 100, friction: 100, duration: 500}
    }), {rootMargin: '0% 0%', once: true});

    const text = props.visual_identification.reason;
    const groupSize = 4;

    const textWords = text.split(' ');
    const groupedText = [];
    for (let i = 0; i < textWords.length; i += groupSize) {
        groupedText.push(textWords.slice(i, i + groupSize).join(' '));
    }

    const [refSlowOp, viewSlowOp] = useInView({
        rootMargin: '0% 0%', once: true
    });

    const slowOpDescription = useTrail(groupedText.length, {
        from: {opacity: 0},
        to: {opacity: 1},
        config: {tension: 100, friction: 500, duration: 300},
        reset: viewSlowOp
    });

    const fonts = props.visual_identification.fonts;
    const groupedFonts = [];
    for (let i = 0; i < fonts.length; i++) {
        groupedFonts.push(fonts[i]);
    }

    const [refFontTrailView, fontTrailView] = useInView({
        rootMargin: '0% 0%', once: true
    });

    const fontTrail = useTrail(groupedFonts.length, {
        from: {x: '-20px', y: '10px', opacity: 0},
        to: {x: '0px', y: '0px', opacity: 1},
        config: config.molasses,
        reset: fontTrailView
    });

    const colors = props.visual_identification.colors;
    const groupedColors = [];
    for (let i = 0; i < colors.length; i++) {
        groupedColors.push(colors[i]);
    }

    const [refColorTrail, viewColorTrail] = useInView({
        rootMargin: '0% 0%', once: true
    });

    const colorTrail = useTrail(groupedColors.length, {
        from: {x: '20px', y: '10px', opacity: 0},
        to: {x: '0px', y: '0px', opacity: 1},
        config: config.molasses,
        reset: viewColorTrail
    });

    const [refSlideUpDescription, slideUpDescription] = useInView(() => ({
        from: {
            transform: 'translateY(100px)',
            transformOrigin: '50% 100%',
            opacity: 0,
        },
        to: {
            transform: 'translateY(0)',
            transformOrigin: '50% 50%',
            opacity: 1
        },
        delay: 400,
        config: {tension: 100, friction: 500, duration: 500}
    }), {rootMargin: '0% 0%', once: true});

    const [refSlideFromSide, slideFromSide] = useInView(() => ({
        from: {
            x: '-100px',
            opacity: 0,
        },
        to: {
            x: '0px',
            opacity: 1
        },
        config: config.molasses
    }), {rootMargin: '0% 0%', once: true});

    const [refSlideFromSide_, slideFromSide_] = useInView(() => ({
        from: {
            x: '100px',
            opacity: 0,
        },
        to: {
            x: '0px',
            opacity: 1
        },
        config: config.molasses
    }), {rootMargin: '0% 0%', once: true});

    const [refDividerLength, dividerLength] = useInView(() => ({
        from: {
            scale: 0,
            opacity: 0,
        },
        to: {
            scale: 1,
            opacity: 1
        },
        config: config.molasses
    }), {rootMargin: '0% 0%', once: true});

    return (
        <div className="flex justify-around mb-24">
            <div className="w-4/5">
                <div className="flex justify-around w-full">
                    <div>
                        <div className="center-div flex-col">
                            <a.p style={slideUpTitle} ref={refSlideUpTitle} className="title text-green mb-4">Visual</a.p>
                            <a.p style={slideUpTitle_} ref={refSlideUpTitle_} className="title">Identification</a.p>
                        </div>

                        <div className="flex justify-around my-12">
                            <a.div style={slideUpDescription} className="description w-3/4 text-center" ref={refSlideUpDescription}>
                                {slowOpDescription.map((props, index) => (
                                    <a.span key={index} style={props} ref={refSlowOp}>
                                        {groupedText[index]}{' '}
                                    </a.span>
                                ))}
                            </a.div>
                        </div>

                        <div className="center-div flex-col mb-24">
                            <a.div style={slideUpWrapper} ref={refSlideUpWrapper}
                                   className="bg-dark-green custom-border w-full sm:w-[90%] h-[28rem] relative z-10"></a.div>
                            <div className="absolute w-[80%] sm:w-[71.5%] h-[28rem] z-50">
                                <div
                                    className="flex justify-between w-[100%] h-24 items-center flex-row relative">
                                    <a.div style={slideFromSide} ref={refSlideFromSide}
                                           className="border-green border-2 w-[49%] h-full center-div">
                                        <h2 className="text-green md:text-4xl font-larabie">Fonts</h2>
                                    </a.div>
                                    <a.div style={slideFromSide_} ref={refSlideFromSide_}
                                           className="border-green border-2 w-[49%] h-full center-div">
                                        <h2 className="text-green md:text-4xl font-larabie">Colors</h2>
                                    </a.div>
                                </div>
                                <div className="flex flex-row items-center h-[80%]">
                                    <div className="flex flex-col w-full mx-4 sm:mx-12 w-4/5">
                                        {fontTrail.map((props, index) => (
                                            <a.div key={index} style={props} ref={refFontTrailView} className="font-box w-full bg-green h-16 my-2 center-div">
                                                <div className="font-box bg-dark-green h-[90%] w-[98%] center-div">
                                                    <h2 className="text-sm md:text-xl lg:text-2xl">{groupedFonts[index]}</h2>
                                                </div>
                                            </a.div>
                                        ))}

                                    </div>
                                    <a.div style={dividerLength} ref={refDividerLength} className="border-l-2 custom-border-center h-4/5"></a.div>
                                    <div className="flex flex-col w-full mx-4 sm:mx-12 w-4/5">

                                        {colorTrail.map((props, index) => (
                                            <a.div key={index} style={props} ref={refColorTrail}
                                                className="font-box w-full bg-green h-16 my-2 center-div">
                                                <div style={{background: groupedColors[index]}} className="small-font-box w-[20%] h-[90%]"></div>
                                                <div className="border-green border-1 h-full w-0.5"></div>
                                                <div
                                                    className="bg-dark-green h-[90%] w-[77%] center-div">
                                                    <h2 className="text-sm md:text-xl lg:text-3xl">{groupedColors[index]}</h2>
                                                </div>
                                            </a.div>
                                        ))}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default VisualIdentification;
