import React, { useEffect, useState } from 'react';
import { a, useInView, useSprings } from '@react-spring/web';

const QueueAnimation = (props) => {
    const [wordIndex, setWordIndex] = useState(0);
    const [isFull, setIsFull] = useState(false);

    const words = props.word;
    const currentWord = `<> ${words[wordIndex]} </>`;

    const [springs, api] = useSprings(
        currentWord.length,
        (index) => ({
            opacity: 0,
            duration: 100, // Apply delay based on index
            delay: 250 * index,
            config: { clamp: true, mass: 3, tension: 150, friction: 38 },
            onRest: () => {
                if (index === currentWord.length - 1) {
                    setIsFull(true);
                }
            },
        }),
    );

    useEffect(() => {
        let timeout = setTimeout(() => {
            // Start forward animation
            api.start((index) => ({
                opacity: 1,
                duration: 100,
                delay: 250 * index,
                immediate: true,
                config: { clamp: true, mass: 3, tension: 150, friction: 38 },
                onRest: () => {
                    if (index === currentWord.length - 1) {
                        // When forward animation is complete, start reverse animation
                        setIsFull(true);
                    }
                },
            }));
        }, 1500);

        return () => {
            clearTimeout(timeout);
        };
    }, [currentWord, api]);

    useEffect(() => {
        if (isFull) {
            let timeout = setTimeout(() => {
                // Start reverse animation
                api.start((index) => ({
                    opacity: 0,
                    duration: 100,
                    delay: 250 * (currentWord.length - index - 1),
                    immediate: true,
                    config: { clamp: true, mass: 3, tension: 150, friction: 38 },
                    onRest: () => {
                        if (index === 0) {
                            // When reverse animation is complete, move to the next word
                            setWordIndex((prevIndex) => (prevIndex + 1) % words.length);
                            setIsFull(false);
                        }
                    },
                }));
            }, 1000);

            return () => {
                clearTimeout(timeout);
            };
        }
    }, [isFull, currentWord, api, words]);

    const breakCenter = {
        display: 'flex',
        justifyContent: 'center',
        justifyItems: 'center',
    };

    return (
        <span>
            {springs.map((props, index) => (
                <a.span key={index} style={{ ...props }} className={breakCenter}>
                    {currentWord[index] === ' ' ? <br/> : currentWord[index]}
                </a.span>
            ))}
        </span>
    );
};

const LetterQueueAnimation = (props) => {
    const [ref, inView] = useInView({
        triggerOnce: true, // Trigger the animation only once
        rootMargin: '0% 50%',
    });

    return (
        <span className={props.className} ref={ref}>
            {inView && <QueueAnimation word={props.word} />}
        </span>
    );
};

export default LetterQueueAnimation;

