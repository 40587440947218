const fetchBlog = async () => {
  const query = `
query BlogPosts {
    publication(host: "journeytocode.io") {
      title
      about {
        markdown
      }
      posts(first:-10) {
        edges {
          node {
            title
            brief
            url
            publishedAt
          }
        }
      }
    }
  }
`;

  const url = "https://gql.hashnode.com/";
  let data;
  await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ query }),
  })
    .then((res) => res.json())
    .then((json) => data = json.data.publication.posts.edges)
    .catch((error) => console.error("Error:", error));
    return data;
};

export default fetchBlog;