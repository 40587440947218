import "../../styles/App.css";
import React, { useEffect, useState } from "react";
import Menu from "./menu";
import WhoAmI from "./whoAmI";
import ToPortfolio from "./toPortfolio";
import Blog from "./blog";
import fetchBlog from "./fetchBlog";
import Social from "./social";
import data from "../../assets/data/data.json";

import fetchGithubProjects, {projects, orgs} from '../../utils/fetchGithubProjects';

function App() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [sectionActive, setIsSectionActive] = useState("home");
  const [blogData, setBlogData] = useState([]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleNavigation = (section) => {
    setIsSectionActive(section);
    false && console.log(sectionActive, "sectionActive");
    document.getElementById(section).scrollIntoView({ behavior: "smooth" });
  };
  console.log('fetchGithubProjects', fetchGithubProjects, projects, orgs);
  useEffect(() => {

    
    fetchBlog().then((blog) => {
      let posts = data.blog_links;
      if (blog)
        posts = blog.map(({ node }) => ({
          title: node.title,
          date: node.publishedAt,
          link: node.url,
        }));
      setBlogData(posts);
    });
  }, []);
  //   console.log(data, blogData)
  return (
    <div className="sm:pt-12 2xl:mx-44 lg:mx-24 md:mx-0 font-callingCode bg-facade text-green border-x-sky-900">
      <div className="text-white">
        <div>
          <div className="bg-dark-green flex md:hidden">
            <button
              onClick={toggleMenu}
              className="text-green p-2 focus:outline-none"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d={
                    menuOpen
                      ? "M6 18L18 6M6 6l12 12"
                      : "M4 6h16M4 12h16M4 18h16"
                  }
                />
              </svg>
            </button>
          </div>
          <div className="hidden md:flex md:justify-center text-white mb-16">
            <div className="w-[80%]">
              <div className="flex justify-between relative text-xs md:text-base">
                <div className="flex flex-col gap-2 justify-around">
                  <div onClick={() => handleNavigation("home")}>
                    <Menu menuNumber="01" menuTitle="Home" />
                  </div>
                  <div onClick={() => handleNavigation("portfolio")}>
                    <Menu menuNumber="02" menuTitle="Portfolio" />
                  </div>
                </div>
                <div className="hidden md:block">
                  <div id="AD">
                    <img src={data.who_am_i[0].logo} alt="" className="" />
                  </div>
                </div>
                <div className="flex flex-col gap-2 justify-around">
                  <div onClick={() => handleNavigation("blog")}>
                    <Menu menuNumber="03" menuTitle="Blog" />
                  </div>
                  <div onClick={() => handleNavigation("social")}>
                    <Menu menuNumber="04" menuTitle="Contact" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Mobile Menu */}
        {menuOpen && (
          <div className="border-b text-white">
            <div className="md:hidden w-9/12 pl-6 flex-col items-start flex  text-xs md:text-base">
              <div onClick={() => handleNavigation("home")} className="py-2">
                <Menu menuNumber="01" menuTitle="Home" />
              </div>
              <div
                onClick={() => handleNavigation("portfolio")}
                className="py-2"
              >
                <Menu menuNumber="02" menuTitle="Portfolio" />
              </div>
              <div onClick={() => handleNavigation("press")} className="py-2">
                <Menu menuNumber="03" menuTitle="Press" />
              </div>
              <div onClick={() => handleNavigation("social")} className="py-2">
                <Menu menuNumber="04" menuTitle="Contact" />
              </div>
            </div>
          </div>
        )}
      </div>
      <div id="home">
        <WhoAmI who={data.who_am_i}></WhoAmI>
      </div>
      <div id="portfolio">
        <ToPortfolio data={data.portfolio}></ToPortfolio>
      </div>
      <div id="press">
        <Blog data={blogData}></Blog>
      </div>
      <div id="social">
        <Social data={data.social_links}></Social>
      </div>
    </div>
  );
}

export default App;
