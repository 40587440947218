import React, {useEffect, useRef} from 'react';
import {a, useSpring} from '@react-spring/web';
import '../../styles/App.css';
import '../../styles/toPortfolio.css'


const ANIMATION_DURATION = 600;
const CURVE_ANIMATION_CONFIG = {duration: 300, mass: 1, tension: 100, friction: 5};
const LINE_ANIMATION_CONFIG = {duration: 500, mass: 1, tension: 100, friction: 5};


const DirectionComponent = () => {
    const ref = useRef(null);
    const ref_ = useRef(null);
    const refHeight = useRef(null);
    const refWidth = useRef(null);

    const [pointer_1, animate1] = useSpring(() => ({
        height: '0px',
        width: '0px',
        transformOrigin: 'bottom',
        config: CURVE_ANIMATION_CONFIG,
    }));

    const [pointer_2, animate2] = useSpring(() => ({
        transformOrigin: 'right',
        width: '0%',
        display: 'none',
        config: LINE_ANIMATION_CONFIG,
    }));

    const [heightPointer, animateHeight] = useSpring(() => ({
        height: '0px', transformOrigin: 'right', config: CURVE_ANIMATION_CONFIG,
    }));

    const [lineAnimation, animateLine] = useSpring(() => ({
        height: '0px', transform: 'scaleX(0)', opacity: 0, config: {duration: ANIMATION_DURATION}
    }));

    const [endTag, animateTag] = useSpring(() => ({
        opacity: 0, config: {duration: ANIMATION_DURATION}
    }));

    const [lastCurve, animateLastCurve] = useSpring(() => ({
        transform: 'scale(0)', config: {duration: ANIMATION_DURATION}
    }));

    const [ballAnimation, animateBall] = useSpring(() => ({
        transform: 'scale(0)', config: {duration: ANIMATION_DURATION}
    }));

    useEffect(() => {
        animate1({
            height: ref.current.offsetHeight + 'px', width: ref.current.offsetWidth + 'px', onRest: () => {
                animate2({
                    width: '100%', display: 'block', onRest: () => {
                        animateHeight({
                            height: refHeight.current.offsetHeight + 'px', onRest: () => {
                                animateLine({
                                    height: refWidth.current.offsetHeight + 'px',
                                    transform: 'scaleX(1)',
                                    opacity: 1,
                                    onRest: () => {
                                        animateTag({
                                            opacity: 1,
                                        })
                                        animateLastCurve({
                                            transform: 'scale(1)', onRest: () => {
                                                animateBall({
                                                    transform: 'scale(1)', onRest: () => {
                                                    }
                                                })
                                            }
                                        })
                                    }
                                })
                            },
                        });
                    },
                });
            },
        });
    }, [animate1, animate2, animateHeight, ref, refHeight, animateBall, animateLine, animateTag, animateLastCurve]);

    return (
        <>
            <div
                className="hidden relative left-[20%] -top-52 w-[10%] md:flex justify-center flex-col items-center">
                <div className="relative">
                    {/*dot*/}
                    <div className="relative z-10 top-2 right-2.5
                                        border-4
                                        border-transparent-green
                                        center-div
                                        h-5 w-5 rounded-3xl">
                        <div className="border-transparent-green h-3 w-3 rounded-3xl bg-white"></div>
                    </div>
                    {/*curve*/}
                    <div className="w-24 h-16" ref={ref}>
                        <a.div style={pointer_1}
                               className="relative border-green border-l-2 border-b-2 rounded-bl-3xl"></a.div>
                    </div>
                    {/*line*/}
                    <div className="w-44" ref={ref_}>
                        <a.div style={pointer_2}
                               className="relative left-24 -top-0.5 border-green border-t-2 border-r-2"></a.div>
                    </div>
                    {/*vertical line*/}
                    <div className="h-28" ref={refHeight}>
                        <a.div style={heightPointer}
                               className="h-1/2 relative left-20 -top-0.5 border-green border-r-2"></a.div>
                    </div>
                    {/* curve */}
                    <div className='w-44 h-14' ref={refWidth}>
                        <a.div style={{transformOrigin: 'top right', overflow: 'hidden', ...lineAnimation}}
                               className="relative z-0 left-24 h-full -top-0.5 border-green border-r-2 border-b-2 rounded-br-3xl">
                        </a.div>
                        <a.span style={endTag}
                                className='relative z-10 left-[50%] -top-5 text-white text-2xl'>&lt;/&gt;</a.span>
                    </div>
                    <a.div style={{transformOrigin: 'top right', overflow: 'hidden', ...lastCurve}}
                           className="relative w-48 h-24 -left-16 -top-[0.2rem] border-t-2 border-green border-l-2 rounded-tl-[80%]"></a.div>
                    <a.div style={ballAnimation} className="relative z-10 -top-2 right-[38%]
                                        border-4
                                        border-transparent-green
                                        center-div
                                        h-5 w-5 rounded-3xl">
                        <div className="border-transparent-green h-3 w-3 rounded-3xl bg-white"></div>
                    </a.div>
                </div>
            </div>
            <div className="md:hidden center-div flex-col my-16">
                {/* line */}
                <div
                    className="relative top-[0.25rem] border-4 border-dark-green h-4 w-4 rounded-3xl bg-white"></div>
                <div className="relative z-10 w-0.5 h-14 border-l-2 bg-gray-green"></div>
                <div
                    className="relative -top-[0.25rem] border-4 border-dark-green h-4 w-4 rounded-3xl bg-white"></div>
            </div>
        </>
    );
};

export default DirectionComponent;
