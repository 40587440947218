import {useState} from 'react';
import {useInView, useSpring, useSpringRef, useChain, config, a} from '@react-spring/web';
import LetterMatrixAnimation from "../shared/matrix_animation";
import RotateAnimation from "../shared/rotate_animation";

const SocialMediaIcon = ({link, icon, handleMouseEnter, handleMouseLeave, isHovered}) => {

    const scale = {
        from: {transform: 'scale(0.1)'},
        to: async (next) => {
            await next({transform: 'scale(1)'});
            while (true) {
                await next({transform: 'scale(0.85)'});
                await next({transform: 'scale(0.9)'});
            }
        },
        delay: Math.random(),
        config: config.molasses,
        once: true,
    };

    const socialRef = useSpringRef();
    const [social] = useSpring(() => ({
        ref: socialRef,
        ...scale
    }));
    useChain([socialRef]);

    const socialText = useSpring({
        from: {color: 'white'},
        to: {color: isHovered ? 'black' : 'white'},
    });

    const socialBg = useSpring({
        from: {backgroundColor: 'transparent', transform: 'scale(0)'},
        to: {backgroundColor: isHovered ? '#00ff94' : 'transparent', transform: isHovered ? 'scale(1)' : 'scale(0)'},
        config: config.molasses
    });

    let iconStyle = {};
    switch (icon) {
        case 'X':
            iconStyle = 'h-16 w-16 md:h-32 md:w-32 top-32';
            break;
        case 'Linkedin':
            iconStyle = 'h-20 md:h-36 w-20 md:w-36 -top-14';
            break;
        case 'Gmail':
            iconStyle = 'h-16 md:h-32 w-16 md:w-32 top-20';
            break;
        case 'Github':
            iconStyle = 'h-20 w-20 md:h-40 md:w-40';
            break;
        default:
            break;
    }

    const handleClick = () => {
        if (link[icon]) {
            window.open(link[icon], '_blank');
        }
    };

    return (
        <a.div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleClick}
            style={social}
            className={`relative center-div border-2 rounded-3xl ${iconStyle} cursor-pointer`}
        >
            <a.div
                style={socialBg}
                className="absolute center-div flex-col rounded-[999px] w-[100%] h-[100%] z-30"
            ></a.div>
            <a.div style={{color: socialText.color}}
                   className="center-div flex-col text-white z-40">
                <div><LetterMatrixAnimation word="<>"/></div>
                <p className="text-xs md:text-base object-cover h-full w-full center-div ">
                    <LetterMatrixAnimation word={icon}/>
                </p>
                <div><LetterMatrixAnimation word="</>"/></div>
            </a.div>
        </a.div>
    );
};


const Social = (props) => {

    const [isHoveredX, setIsHoveredX] = useState(false);
    const [isHoveredLinkedin, setIsHoveredLinkedin] = useState(false);
    const [isHoveredGmail, setIsHoveredGmail] = useState(false);
    const [isHoveredGithub, setIsHoveredGithub] = useState(false);

    const socialMediaIcons = [
        {name: 'Github', state: isHoveredGithub, setState: setIsHoveredGithub},
        {name: 'X', state: isHoveredX, setState: setIsHoveredX},
        {name: 'Linkedin', state: isHoveredLinkedin, setState: setIsHoveredLinkedin},
        {name: 'Gmail', state: isHoveredGmail, setState: setIsHoveredGmail},
        
    ];

    const handleMouseEnter = (setState) => () => {
        setState(true);
    };

    const handleMouseLeave = (setState) => () => {
        setState(false);
    };

    const [ref_, pointerBall] = useInView(() => ({
            from: {opacity: 0, transform: 0}, // Start with 0 height
            to: [{opacity: 1, transform: 1.5}, {transform: 1}], // End with 100% height
            delay: 1500,
            config: {duration: 500}, // Adjust the duration as needed
        }),
        {rootMargin: '0% 0%'}
    );

    const scale = {
        from: {transform: 'scale(0.1)'},
        to: async (next) => {
            await next({transform: 'scale(1)'}); // Initial scale from 0 to 1
            while (true) {
                await next({transform: 'scale(0.85)'});
                await next({transform: 'scale(0.9)'});
            }
        },
        config: config.molasses,
        reset: false, // Reset to initial state when animation is complete
    };

    return (
        <div>
            <div className="center-div flex-col my-16 clear-both">
                {/* line */}
                <a.div ref={ref_} style={{...pointerBall, ...scale}}
                       className="relative z-0 top-1.5 bg-transparent-green h-5 w-5 rounded-3xl center-div">
                    <div className="h-2 w-2 rounded-3xl bg-white"></div>
                </a.div>
                <div className="relative z-10 w-0.5 h-14 border-l-2 bg-gray-green"></div>
                <a.div ref={ref_} style={{...pointerBall, ...scale}}
                       className="relative z-10 bottom-1.5 bg-transparent-green h-5 w-5 rounded-3xl center-div">
                    <div className="h-2 w-2 rounded-3xl bg-white"></div>
                </a.div>
            </div>
            <div className="relative -top-10 flex flex-row justify-center mb-20">
                <div className="flex flex-col text-light-green text-xs md:text-base">
                    <p className="text-gray flex flex-row relative right-10"><LetterMatrixAnimation word="<h2>"/></p>
                    <p className="title text-white"><RotateAnimation word="Connect with me"/></p>
                    <p className="text-gray flex flex-row-reverse relative left-10 sm:-mt-3 lg:-mt-6">
                        <LetterMatrixAnimation word="</h2>"/></p>
                </div>
            </div>
            <div className="relative center-div">
                       <div className="flex justify-around mb-44">
                    {socialMediaIcons.map((socialMediaIcon, index) => (
                        <SocialMediaIcon
                            link={props.data[index]}
                            key={index}
                            icon={socialMediaIcon.name}
                            handleMouseEnter={handleMouseEnter(socialMediaIcon.setState)}
                            handleMouseLeave={handleMouseLeave(socialMediaIcon.setState)}
                            isHovered={socialMediaIcon.state}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Social;
