import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './components/homepage/App';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Portfolio from "./components/detail/portfolio";
import ScrollToTop from "./components/shared/scrollToTop";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <>
        <BrowserRouter>
            <ScrollToTop />
            <Routes>
                <Route path="/" element={<App />} />
                <Route path="/portfolio/:id" element={<Portfolio />} />
            </Routes>
        </BrowserRouter>
    </>
);

