const username = "mnichols08";
const organizations = ["chingu-voyages", "mnix-dev"];
let topic = "personal-project";

const projectsCollection = {
  personal: [],
  group: [],
};
const projects = [];
const orgs = [];

fetch(`https://api.github.com/users/${username}`)
  .then((res) => res.json())
  .then((user) => {
  })
  .catch((err) => console.error(err));

fetch(
  `https://api.github.com/search/repositories?q=user:${username}+topic:${topic}`
)
  .then((res) => res.json())
  .then((data) => {
    data.items.forEach((repo) => {
      fetch(
        `https://api.github.com/repos/${username}/${repo.name}/contents/project-data.json`
      )
        .then((res) => {
          if (res.ok) return res.json();
          throw new Error("No project-data.json found");
        })
        .then((data) => {
          const projectData = JSON.parse(atob(data.content));
          projects.push(projectData);
          projectsCollection.personal.push(projectData);
        })
        .catch((err) => console.error(err));
    });
  })
  .catch((err) => console.error(err));

topic = "group-project";

organizations.forEach((organization) => {
fetch(
  `https://api.github.com/search/repositories?q=org:${organization}+topic:${topic}`
)
  .then((res) => res.json())
  .then((data) => {
    data.items.forEach((repo) => {
      fetch(
        `https://api.github.com/repos/${organization}/${repo.name}/contents/project-data.json`
      )
        .then((res) => {
          if (res.ok) return res.json();
          throw new Error("No project-data.json found");
        })
        .then((data) => {
          const projectData = JSON.parse(atob(data.content));
          projects.push(projectData);
          projectsCollection.group.push(projectData);
        })
        .catch((err) => console.error(err));
    });
  })
  .catch((err) => console.error(err));
});
export { projects, orgs };
export default projectsCollection;
