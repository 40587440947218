import React from 'react';
import {useTrail, config, a, useInView} from '@react-spring/web'

function Wireframe(props) {


    const [refScaleItImage, scaleItImage] = useInView(
        () => ({
            from: {
                scale: 0,
                opacity: 0,
            },
            to: {
                scale: 1,
                opacity: 1,
            },
            delay: 200,
            config: config.molasses,
        }),
        {rootMargin: '0% 0%', once: true}
    );

    const [refScaleItBg, scaleItBg] = useInView(
        () => ({
            from: {
                scale: 0,
                opacity: 0,
            },
            to: {
                scale: 1,
                opacity: 1,
            },
            config: config.molasses,
        }),
        {rootMargin: '0% 0%', once: true}
    );

    const [refScaleIt, scaleIt] = useInView(
        () => ({
            from: {
                scale: 0,
                y: '100px',
                x: '100px',
                opacity: 0,
            },
            to: {
                scale: 1,
                y: '0px',
                x: '0px',
                opacity: 1,
            },
            config: config.molasses,
        }),
        {rootMargin: '0% 0%', once: true}
    );

    const [refScaleIt_, scaleIt_] = useInView(
        () => ({
            from: {
                scale: 0,
                y: '100px',
                x: '-100px',
                opacity: 0,
            },
            to: {
                scale: 1,
                y: '0px',
                x: '0px',
                opacity: 1,
            },
            config: config.molasses,
        }),
        {rootMargin: '0% 0%', once: true}
    );

    const [refScaleItBottom, scaleItBottom] = useInView(
        () => ({
            from: {
                scale: 0,
                y: '-100px',
                x: '100px',
                opacity: 0,
            },
            to: {
                scale: 1,
                y: '0px',
                x: '0px',
                opacity: 1,
            },
            config: config.molasses,
        }),
        {rootMargin: '0% 0%', once: true}
    );

    const [refScaleItBottom_, scaleItBottom_] = useInView(
        () => ({
            from: {
                scale: 0,
                y: '-100px',
                x: '-100px',
                opacity: 0,
            },
            to: {
                scale: 1,
                y: '0px',
                x: '0px',
                opacity: 1,
            },
            config: config.molasses,
        }),
        {rootMargin: '0% 0%', once: true}
    );

    const text = props.description;
    const groupSize = 4;

    const textWords = text.split(' ');
    const groupedText = [];
    for (let i = 0; i < textWords.length; i += groupSize) {
        groupedText.push(textWords.slice(i, i + groupSize).join(' '));
    }

    const [refSlowOp, viewSlowOp] = useInView({
        rootMargin: '0% 0%', once: true
    });

    const slowOpDescription = useTrail(groupedText.length, {
        from: {opacity: 0},
        to: {opacity: 1},
        delay: 200,
        config: {tension: 100, friction: 500, duration: 300},
        reset: viewSlowOp
    });


    const [refSlideUpTitle, slideUpTitle] = useInView(
        () => ({
            from: {
                transform: 'translateY(100px)',
                transformOrigin: '50% 100%',
                opacity: 0,
            },
            to: {
                transform: 'translateY(0)',
                transformOrigin: '50% 50%',
                opacity: 1,
            },
            config: {tension: 100, friction: 100, duration: 500},
        }),
        {rootMargin: '0% 0%', once: true}
    );

    const [refMoveUp, moveUp] = useInView(
        () => ({
            from: {
                y: '-10px',
                opacity: 0,
            },
            to: {
                y: '0px',
                opacity: 1,
            },
            delay: 1000,
            config: config.molasses,
        }),
        {rootMargin: '0% 0%', once: true}
    );

    const [refMoveDown, moveDown] = useInView(
        () => ({
            from: {
                y: '10px',
                opacity: 0,
            },
            to: {
                y: '0px',
                opacity: 1,
            },
            delay: 1000,
            config: config.molasses,
        }),
        {rootMargin: '0% 0%', once: true}
    );

    const openSiteInNewTab = (img) => {
        window.open(img, '_blank');
    };

    return (
        <>
            <div className="flex justify-around">
                <div className="w-11/12">
                    <div className="flex justify-around w-full">
                        <div>
                            <div className="flex justify-around mt-24">
                                <a.p style={slideUpTitle} ref={refSlideUpTitle} className="title">Wireframe</a.p>
                            </div>
                            <div className="flex justify-around">
                                <div className="text-1xs sm:text-sm md:text-lg lg:text-2xl w-3/4 text-center">
                                    {slowOpDescription.map((props, index) => (
                                        <a.span key={index} style={props} ref={refSlowOp}>
                                            {groupedText[index]}{' '}
                                        </a.span>
                                    ))}
                                </div>
                            </div>
                            <div className="flex flex-col items-center">
                                <div
                                    className="flex flex-row justify-between relative w-11/12 sm:w-[85%] md:w-[88%] lg:w-[84.5%] top-8 sm:top-16 md:top-24">
                                    <a.div style={scaleIt} ref={refScaleIt}
                                           className="border-t-8 border-l-8 corners"></a.div>
                                    <a.div style={scaleIt_} ref={refScaleIt_}
                                           className="border-t-8 border-r-8 corners"></a.div>
                                </div>
                                <div className="w-4/5">
                                    <a.div style={moveUp} ref={refMoveUp}
                                           className="flex justify-center text-green" onClick={openSiteInNewTab(props.link)}>Check out the website
                                    </a.div>
                                    <div className="center-div relative h-[30rem] left-0.5">
                                        <div className="center-div border-green border-dashed border-2 w-full h-full">
                                            <a.div style={scaleItBg} ref={refScaleItBg}
                                                   className="absolute bg-dark-green h-[99%] w-[99%] sm:w-[98%] sm:h-[98%]"></a.div>
                                            <a.div style={scaleItImage} ref={refScaleItImage}
                                                   className="flex justify-center border-green border-2 absolute w-[96%] h-[94%] sm:w-[96%] sm:h-[94%]">
                                               <iframe title={props.title} style={{background: `#fff`, width: '100vw'}} src={props.link}></iframe>
                                            </a.div>
                                        </div>
                                    </div>
                                    <a.div style={moveDown} ref={refMoveDown}
                                           className="flex justify-center text-green">Interact to learn more
                                    </a.div>
                                </div>
                                <div
                                    className="flex flex-row justify-between relative w-11/12 sm:w-[85%] md:w-[88%] lg:w-[84.5%] bottom-8 sm:bottom-16 md:bottom-24">
                                    <a.div style={scaleItBottom} ref={refScaleItBottom}
                                           className="border-b-8 border-l-8 corners"></a.div>
                                    <a.div style={scaleItBottom_} ref={refScaleItBottom_}
                                           className="border-b-8 border-r-8 corners"></a.div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Wireframe;
