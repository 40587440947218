import React from 'react';
import {
    useSpring,
    useTrail,
    config,
    a,
    useSprings,
    useInView
} from '@react-spring/web'
import LetterQueueAnimation from "../shared/letter_queue";


const configs = {tension: 200, friction: 80}
const commonSpringConfig = {
    from: {transform: 'translateY(100px)', transformOrigin: '50% 100%'},
    to: {transform: 'translateY(0)', transformOrigin: '50% 50%'},
    config: configs,
};

const delayValues = [0, 200, 400]; // Delay values for each animation

const titles = ['Which', 'language', 'i used']; // Titles for each element
function LangUsed(props) {

    const prop = props.language;

    const [ref, isInViewSpring] = useInView({
        rootMargin: '0% 0%',
        once: true
    })
    const springs = useSprings(
        titles.length,
        titles.map((title, index) => ({
            ...commonSpringConfig,
            delay: delayValues[index], // Apply delay based on index
            immediate: (key) => key === 'translateY',
            reset: isInViewSpring
        })),
    );

    const [ref_, isInViewTitle] = useInView({
        rootMargin: '0% 0%',
        once: true
    });

    const titleOpacity = useSpring({
        from: {opacity: 0},
        to: {opacity: 1},
        config: configs,
        reset: isInViewTitle,
        delay: 200,
    });

    const text = prop.reason;
    const groupSize = 4;

    const textWords = text.split(' ');
    const groupedText = [];
    for (let i = 0; i < textWords.length; i += groupSize) {
        groupedText.push(textWords.slice(i, i + groupSize).join(' '));
    }

    const [ref__, isInView] = useInView({
        rootMargin: '0% 0%',
        once: true
    })

    const slowOpDescription = useTrail(groupedText.length, {
        from: {opacity: 0},
        to: {opacity: 1},
        config: configs,
        delay: 600,
        reset: isInView
    });

    const slideUpDescription = useSpring({
        // transform: isInView ?  'scale(4)' :  'scale(0)',
        ...commonSpringConfig,
        delay: 500,
        reset: isInView
    });


    const [refRotatePulse, isInViewRotatePulse] = useInView({
        rootMargin: '0% 0%',
        once: true
    })

    const animationProps = useSpring({
        from: {
            transform: 'rotate(-120deg) scale(0)',
        },
        to: {
            transform: 'rotate(0deg) scale(1)',
        },
        config: {...config.molasses, duration: 2000},
        reset: isInViewRotatePulse
    });

    const bigPulseAnimation = useSpring({
        from: {boxShadow: '0px 0px 600px rgba(36, 36, 43, 0.5)'},
        to: async (next) => {
            while (true) {
                await next({boxShadow: '0px 0px 300px rgba(36, 36, 43, 0.5)'});
                await next({boxShadow: '0px 0px 150px rgba(36, 36, 43, 0.5)'});
                await next({boxShadow: '0px 0px 100px rgba(36, 36, 43, 0.5)'});
                await next({boxShadow: '0px 0px 0px rgba(36, 36, 43, 0.5)'});
            }
        },
        config: config.molasses,
        reset: isInViewRotatePulse
    });

    return (
        <div className="flex justify-around col-divider">
            <div className="w-11/12">
                <div className="flex justify-around w-full">
                    <div
                        className="center-div flex-col md:items-stretch md:flex-row md:justify-around">
                        <div className="flex w-11/12 md:w-1/3 gap-4 pb-16 flex-col justify-around">
                            {springs.map((animation, index) => (
                                <a.div key={index} style={animation} ref={ref}>
                                    <a.span style={titleOpacity} ref={ref_}
                                            className={`title ${index === 2 ? 'text-green' : ''}`}>
                                        {titles[index]}
                                    </a.span>
                                </a.div>
                            ))}
                            <a.div style={slideUpDescription} ref={ref__}>
                                {slowOpDescription.map((props, index) => (
                                    <a.span key={index} style={props} ref={ref__}
                                            className="text-1xs sm:text-sm md:text-lg lg:text-xl ">
                                        {groupedText[index]}{' '}
                                    </a.span>
                                ))}
                            </a.div>
                        </div>
                        <div>
                            {/*<a.div style={{...animationProps, ...smallPulseAnimation}} className="absolute z-10 w-[200px] h-[200px] sm:w-[290px] sm:h-[290px] bg-transparent rounded-[9999px]"></a.div>*/}
                            <a.div style={{...animationProps, ...bigPulseAnimation}} className="donut absolute z-20" ref={refRotatePulse}>
                                <div className="relative shape-full z-50 center-div">
                                    <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 opacity-5
                                        scale-y-[-1] rotate-[135deg] font-callingCode text-9xl font-black z-0">&lt;/&gt;</div>

                                    <div className="clips shape-full center-div z-10">
                                        <div className="absolute shape-full top-[2%] left-[5%]">
                                            <div className="white-circle left-[75%] top-[15%] z-10"></div>
                                            <div className="white-arc w-[90%] h-[90%] rotate-[90deg] z-0"></div>
                                            <div className="white-circle left-[75%] bottom-[15%]"></div>
                                        </div>
                                    </div>

                                    <div className="clips-2 shape-full center-div z-20">
                                        <div className="absolute shape-full top-[7%] left-[10%]">
                                            <div className="white-circle left-[10px] top-[13%] left-[67%] z-10"></div>
                                            <div className="white-arc w-[80%] h-[80%] rotate-[90deg] z-0"></div>
                                            <div className="white-circle left-[67%] bottom-[13%]"></div>
                                        </div>
                                    </div>

                                    <div className="clips-3 shape-full center-div rotate-180 z-20">
                                        <div className="absolute shape-full top-[12%] left-[15%]">
                                            <div className="white-circle left-[25%] top-[3%] z-10"></div>
                                            <div className="white-arc w-[70%] h-[70%] rotate-[300deg] z-0"></div>
                                            <div className="white-circle left-[0%] bottom-[26%]"></div>
                                        </div>
                                    </div>

                                    <div
                                        className="center-div flex-col absolute text-green font-larabie text-xl sm:text-3xl font-extrabold rounded-[999px] border border-white w-[55%] h-[55%] z-30">
                                        <div className="text-center">
                                            <LetterQueueAnimation word={prop.language}/>
                                        </div>
                                    </div>
                                </div>
                            </a.div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default LangUsed;
