import React from 'react';
import {useSpring, useTrail, config, a, useInView} from '@react-spring/web'
import {NavLink} from "react-router-dom";

function Intro(props) {


    const [refLetsGo, letsGo] = useInView(()=> ({
        from: {scale: 0},
        to: {scale: 1},
        delay: 100,
        config: config.molasses // Adjust animation config
    }),{rootMargin: '0% 0%', once: true});

    const [refSecondArrow, secondArrow] = useInView(() => ({
        from: {opacity: 0},
        to: {opacity: 1},
        delay: 1000,
        config: config.molasses
    }), {rootMargin: '0% 0%', once: true});

    const [refThirdArrow, thirdArrow] = useInView(() => ({
        from: {opacity: 0},
        to: {opacity: 1},
        delay: 3000,
        config: {tension: 100, friction: 500, duration: 4000}
    }), {rootMargin: '0% 0%', once: true});

    const [refSlideUp, slideUpTitle] = useInView(() => ({
            from: {
                transform: 'translateY(100px)',
                transformOrigin: '50% 100%',
                opacity: 0,
            },
            to: {
                transform: 'translateY(0)',
                transformOrigin: '50% 50%',
                opacity: 1,
            },
            config: {tension: 100, friction: 100, duration: 500}
        }), {rootMargin: '0% 0%', once: true}
    );

    const text = props.description;
    const groupSize = 4;
    const textWords = text.split(' ');
    const groupedText = [];
    for (let i = 0; i < textWords.length; i += groupSize) {
        groupedText.push(textWords.slice(i, i + groupSize).join(' '));
    }

    const [refSlowOp, viewSlowOp] = useInView({
        rootMargin: '0% 0%', once: true
    });

    const slowOpDescription = useTrail(groupedText.length, {
        from: {opacity: 0},
        to: {opacity: 1},
        config: {tension: 100, friction: 500, duration: 300},
        reset: viewSlowOp
    });

    const [refShowCase, showCaseView] = useInView(
        {once: true}
    );
    //This is a masterpiece name!
    const useShowcaseSpring = (toTransformOrigin) => {
        return useSpring({
            background: '#111119',
            from: { transform: 'scaleX(1)', transformOrigin: 'center', background: '#111119'},
            to: { transform: `scaleX(0)`, transformOrigin: toTransformOrigin },
            delay: 200,
            config: config.molasses,
            reset: showCaseView
        });
    };

    const openShowcase = useShowcaseSpring('left');
    const openShowcase__ = useShowcaseSpring('right');

    return (
        <div className="flex justify-around col-divider mt-4 lg:mt-16">
            <div className="w-full sm:w-11/12">
                <div className="flex justify-around w-full">
                    <div className="center-div flex-col md:items-stretch md:flex-row md:justify-around">
                        <div className="flex w-11/12 md:w-1/3 gap-6 pb-16 flex-col justify-around items-start">
                            <a.p style={slideUpTitle} className="title" ref={refSlideUp}>{props.project}</a.p>
                            <a.div style={slideUpTitle} ref={refSlideUp}>
                                {slowOpDescription.map((props, index) => (
                                    <a.span key={index} style={props} className="text-1xs sm:text-sm md:text-lg lg:text-xl" ref={refSlowOp}>
                                        {groupedText[index]}{' '}
                                    </a.span>
                                ))}
                            </a.div>
                            {/*lets go*/}
                            <div className="inline-flex relative -left-[1.85rem] sm:-left-[5.2rem] z-50 pt-6">
                                <a.div style={thirdArrow} ref={refThirdArrow}
                                       className="small-left-arrow lets-go-arrow-size left-[1.85rem] sm:left-[5.2rem] bg-dark-green"></a.div>
                                <a.div style={secondArrow} ref={refSecondArrow}
                                       className="small-left-arrow lets-go-arrow-size left-5 sm:left-14 bg-medium-green"></a.div>
                                <div className="small-left-arrow lets-go-arrow-size left-2.5 sm:left-7 bg-green"></div>
                                <a.div style={{...letsGo}} ref={refLetsGo} className="lets-go bg-green w-16 h-6 sm:w-48 sm:h-14 center-div">
                                    <div className="lets-go bg-dark-green center-div w-[98%] h-[95%]">
                                        <NavLink to={props.link}>
                                            <h6 className="font-larabie text-green sm:py-4 text-2xs md:text-base">Let's
                                                Go</h6>
                                        </NavLink>
                                    </div>
                                </a.div>
                                <div
                                    className="small-right-arrow lets-go-arrow-size right-2.5 sm:right-7 bg-green"></div>
                                <a.div style={secondArrow} ref={refSecondArrow}
                                       className="small-right-arrow lets-go-arrow-size right-5 sm:right-14 bg-medium-green"></a.div>
                                <a.div style={thirdArrow} ref={refThirdArrow}
                                       className="small-right-arrow lets-go-arrow-size right-[1.85rem] sm:right-[5.2rem] flex bg-dark-green"></a.div>
                            </div>
                        </div>
                        <div className="relative">
                            <div>
                                <a.div style={openShowcase} className="absolute w-1/2 h-72 md:w-36 md:h-96 bg-transparent z-10 bg-blue-700" ref={refShowCase}></a.div>
                                <a.div style={openShowcase__} className="absolute right-0 w-1/2 h-72 md:w-36 md:h-96 bg-transparent z-10 bg-red-700" ref={refShowCase}></a.div>
                            </div>
                            <div className="showcase-gon bg-green absolute w-52 h-72 md:w-72 md:h-96 center-div z-0">
                                <div className="showcase-gon bg-facade h-[99%] w-[99%] center-div">
                                    <div className="showcase-gon bg-white h-[95%] w-[94%] center-div">
                                        <img src={`../${props.image}`} alt="" className="shape-full object-cover"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Intro;
