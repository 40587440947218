import React, {useState} from 'react';
import {useSpring, a, config} from '@react-spring/web';
import LetterMatrixAnimation from "../shared/matrix_animation";

function Menu({ menuNumber, menuTitle }) {
    const [isHovered, setIsHovered] = useState(false);


    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const borderScale = useSpring({
        from: { opacity: 0, transform: `scaleX(0)`},
        to: [{ opacity: isHovered ? 1 : 0, transform: `scaleX(1)`}, { transform: isHovered ? `scaleX(1)`: "scaleX(0)"}],
        config: config.gentle,
    });

    const menuSlide = useSpring({
        from: { x: '0px' },
        to: { x: isHovered ? '25px' : '0px' },
        config: config.default,
    });

    const transitions = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1 },
        config: { duration: 1 },
    });

    const menuText = '//' + menuNumber + '. <' + menuTitle + '/>';

    return (
        <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className="cursor-pointer h-12 relative bottom-12"
        >
            <a.div style={borderScale} className="relative top-8 border-2 border-green bg-dark-green py-5 px-28 text-green" />
            <a.div style={{...menuSlide, ...transitions}}><LetterMatrixAnimation word={menuText} /></a.div>
        </div>
    );
}

export default Menu;
