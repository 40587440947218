import React, { useEffect, useRef } from 'react';
import {useSpring, a, useSpringRef, useChain, useInView, config} from '@react-spring/web'
import { useNavigate }  from 'react-router-dom';

function Arrow() {

    const navigate = useNavigate();

    const [refBigArrow, bigArrow] = useInView(() => ({
        from: {x: 50},
        to: {x: 0,},
        delay: 500,
        config: config.molasses
    }), {rootMargin: '0% 0%', once: true});

    const commonSpringConfig = {
        from: { opacity: 0 },
        to: { opacity: 1 },
    };
    const arrowOne = useSpringRef();
    const [arrow_1] = useSpring(() => ({
        ref: arrowOne,
        ...commonSpringConfig
    }))
    const arrowTwo = useSpringRef();
    const [arrow_2] = useSpring(() => ({
        ref: arrowTwo,
        ...commonSpringConfig
    }))
    const arrowThree = useSpringRef();
    const [arrow_3] = useSpring(() => ({
        ref: arrowThree,
        ...commonSpringConfig
    }));
    useChain([arrowOne, arrowTwo, arrowThree], [1, 1.2, 1.4], 600);

    const pointerWidth = useSpring({
        from: { width: '0%', opacity: 0 }, // Start with 0 height
        to: { width: '100%', opacity: 1 }, // End with 100% height
        delay: 1000,
        config: { duration: 300, mass: 1, tension: 1000, friction: 5}, // Adjust the duration as needed
    });
    const pointerBall = useSpring({
        from: { opacity: 0, transform: 0 }, // Start with 0 height
        to: [{ opacity: 1, transform: 1.5 }, {transform: 1}], // End with 100% height
        delay: 1500,
        config: { duration: 500 }, // Adjust the duration as needed
    });

    const ref = useRef(null);
    const refSmall = useRef(null);

    const [heightPointer, animateHeight] = useSpring(() => ({
        height: '0px',
        transformOrigin: 'left', // Set the transform origin to the left
        config: { duration: 300, mass: 1, tension: 100, friction: 5},
    }), []);

    useEffect(() => {
        animateHeight({
            height: ref.current.offsetHeight + 'px',
            delay: 1300
        });
    }, [animateHeight, ref]);

    // small devices
    const [heightPointerSmall, animateHeightSmall] = useSpring(() => ({
        height: '0px',
        transformOrigin: 'left', // Set the transform origin to the left
        config: { duration: 300, mass: 1, tension: 100, friction: 5},
    }), []);
    useEffect(() => {
        animateHeightSmall({
            height: refSmall.current.offsetHeight + 'px',
            delay: 1300
        });
    }, [animateHeightSmall, refSmall]);

    return (
        <div className="flex justify-center">
            <div className="flex flex-row flex-inline lg:items-center relative md:pl-5 mb-4 lg:mb-24 w-full xl:w-10/12">
                {/*Back to home*/}
                <div className="inline-flex relative">
                    <a.div style={{ opacity: arrow_3.opacity }}
                                  className="shadow-arrow-bg left-5 sm:left-5 md:left-10 center-div opacity-25">
                        <div className="shadow-arrow"></div>
                    </a.div>
                    <a.div style={{ opacity: arrow_2.opacity }}
                                  className="shadow-arrow-bg left-3.5 sm:left-3.5 md:left-[1.70rem] center-div opacity-50">
                        <div className="shadow-arrow"></div>
                    </a.div>
                    <a.div
                        className="shadow-arrow-bg left-2 sm:left-2 md:left-3.5 center-div"
                        style={{ opacity: arrow_1.opacity }}
                    >
                        <div className="shadow-arrow"></div>
                    </a.div>
                    <a.div onClick={() => navigate("/")}
                        className="relative left-arrow bg-green w-32 h-6 sm:w-40 sm:h-7 md:w-80 md:h-14 center-div cursor-pointer"
                        style={{transform: bigArrow.x.to((x) => `translateX(${x}%)`)}} ref={refBigArrow}>
                        <div className="left-arrow center-div h-[96%] w-[98%] bg-gradient-to-r from-facade via-via-gradient to-facade">
                            <h2 className="font-larabie text-green flex justify-around items-center font-normal text-1xs sm:text-xs md:text-lg lg:text-xl">
                                Back to home
                            </h2>
                        </div>
                    </a.div>
                </div>
                {/*Pointing arrow large devices*/}
                <div className="hidden lg:block flex justify-start items-center relative left-0.5 w-4/5 h-32">
                    <div className="flex justify-start items-center relative h-32 right-3 w-[30%] xl:w-[40%]" ref={ref}>
                        <a.div style={pointerWidth} className="skew-x-[35deg] top-16 relative left-11 h-32">
                            <a.div className="border-2 border-l-transparent border-b-transparent h-full
                                            border-green" style={heightPointer}>
                            </a.div>
                        </a.div>
                        {/* Dot 'o' */}
                        <a.div style={{transform: pointerBall.transform.to((transform) => `scale(${transform})`)}}
                               className="relative z-10 top-32 left-20 bg-transparent-green h-5 w-5 rounded-3xl center-div">
                            <div className="h-2 w-2 rounded-3xl bg-white"></div>
                        </a.div>
                    </div>
                </div>
                {/*straight arrow  small devices*/}
                <div ref={refSmall} className="lg:hidden green w-10 h-10 md:w-20 md:h-20 md:mb-10">
                    <a.div style={{...pointerWidth}} className="lg:hidden relative top-3 right-1 md:top-6 md:right-3 center-div flex-col">
                        {/* line */}
                        <a.div  style={{...heightPointerSmall}} className="border-l-transparent border-b-transparent border-2 border-green w-10 h-10 md:w-20 md:h-20"></a.div>
                        <a.div style={{transform: pointerBall.transform.to((transform) => `scale(${transform})`)}}
                               className="relative z-10 left-5 md:left-10 bottom-2 border-4 border-dark-green h-4 w-4 rounded-3xl bg-white"></a.div>
                    </a.div>
                </div>
            </div>
        </div>
    );
}

export default Arrow;
