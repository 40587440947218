import React, { useState} from 'react';
import {useTrail, config, a, useInView} from '@react-spring/web'

function Goal(props) {
    const [currentIndex, setCurrentIndex] = useState(0);


    const [refScaleUp, scale_up] = useInView(() => ({
        from: {scale: 0},
        to: {scale: 1},
        config: config.molasses, // Adjust animation config
    }), {rootMargin: '0% 0%', once: true})

    const [refSlideUpTitle_, slideUpTitle_] = useInView(() => ({
        from: {
            transform: `translateY(-100px) scaleY(-1)`,
            opacity: 0,
        },
        to: {
            transform: `translateY(0px) scaleY(-1)`,
            opacity: 1,
        },
        config: config.molasses
    }), {rootMargin: '0% 0%', once: true});

    const [refSlideUpTitle, slideUpTitle] = useInView(() => ({
        from: {
            transform: 'translateY(100px)',
            transformOrigin: '50% 100%',
            opacity: 0,
        },
        to: {
            transform: 'translateY(0)',
            transformOrigin: '50% 50%',
            opacity: 1,
        },
        config: {tension: 100, friction: 100, duration: 500}
    }), {rootMargin: '0% 0%', once: true});

    const text = props.project_goal[currentIndex].description;
    const groupSize = 4;

    const textWords = text.split(' ');
    const groupedText = [];
    for (let i = 0; i < textWords.length; i += groupSize) {
        groupedText.push(textWords.slice(i, i + groupSize).join(' '));
    }

    const [refSlowOp, viewSlowOp] = useInView({
        rootMargin: '0% 0%', once: true
    });

    const slowOpDescription = useTrail(groupedText.length, {
        from: {opacity: 0},
        to: {opacity: 1},
        config: {tension: 100, friction: 500, duration: 300},
        reset: viewSlowOp
    });

    const [refSlideUpDescription, slideUpDescription] = useInView(() => ({
        from: {
            transform: 'translateY(100px)',
            transformOrigin: '50% 100%',
            opacity: 0,
        },
        to: {
            transform: 'translateY(0)',
            transformOrigin: '50% 50%',
            opacity: 1
        },
        delay: 200,
        config: config.molasses
    }), {rootMargin: '0% 0%', once: true});

    const [refSlideUpDescription_, slideUpDescription_] = useInView(() => ({
        from: {
            transform: `translateY(-100px) scaleY(-1)`,
            opacity: 0,
        },
        to: {
            transform: `translateY(0) scaleY(-1)`,
            opacity: 1,
        },
        delay: 500,
        config: config.molasses
    }), {rootMargin: '0% 0%', once: true});

    const [refGoalExtra, goalExtra] = useInView(() => ({
        from: {
            transform: `translateY(100px) scaleY(1)`,
            opacity: 0,
        },
        to: {
            transform: `translateY(0) scaleY(-1)`,
            opacity: 1,
        },
        delay: 500,
        config: config.molasses
    }), {rootMargin: '0% 0%', once: true});

    const [refGoalExtra_, goalExtra_] = useInView(() => ({
        from: {
            transform: `translateY(-40px) scaleY(-1)`,
            opacity: 0,
        },
        to: {
            transform: `translateY(0) scaleY(-1)`,
            opacity: 1,
        },
        delay: 500,
        config: config.molasses
    }), {rootMargin: '0% 0%', once: true});

    const [refOpenShowcase, openShowcase] = useInView(() => ({
        background: '#111119',
        from: {transform: 'scaleX(1)', transformOrigin: 'center', background: '#111119'},
        to: {transform: 'scaleX(0)', transformOrigin: 'left'},
        delay: 200,
        config: config.molasses
    }), {rootMargin: '0% 0%', once: true});

    const [refOpenShowCase__, openShowcase__] = useInView(() => ({
        background: '#111119',
        from: {transform: 'scaleX(1)', transformOrigin: 'center', background: '#111119'},
        to: {transform: 'scaleX(0)', transformOrigin: 'right'},
        delay: 200,
        config: config.molasses
    }), {rootMargin: '0% 0%', once: true});

    const [refArrowInward, arrowInward] = useInView(() => ({
        from: {x: '-100px', opacity: 0},
        to: {x: '0px', opacity: 1},
        delay: 200,
        config: config.molasses
    }), {rootMargin: '0% 0%', once: true});

    const [refArrowInward_, arrowInward_] = useInView(() => ({
        from: {x: '100px', opacity: 0},
        to: {x: '0px', opacity: 1},
        delay: 200,
        config: config.molasses
    }), {rootMargin: '0% 0%', once: true});


    const handlePrevious = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? props.project_goal.length - 1 : prevIndex - 1
        );
    };
    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % props.project_goal.length);
    };

    return (
        <div>
            <div className="flex justify-around col-divider">
                <div className="w-full sm:w-9/12 md:w-full">
                    <div className="flex justify-around w-full">
                        <div>
                            <div className="flex justify-around">
                                <a.p style={slideUpTitle} ref={refSlideUpTitle} className="title">Project <span
                                    className="text-green">Goal</span></a.p>
                            </div>
                            <div className="center-div">
                                <div className="w-9/12">
                                    <a.p style={slideUpDescription} ref={refSlideUpDescription}
                                         className="description w-3/4 text-center">
                                        {props.project_goal[currentIndex].goal}
                                    </a.p>
                                </div>
                            </div>
                            <div className="flex justify-center relative">
                                <div>
                                    <a.div style={scale_up} ref={refScaleUp} className="num-counter center-div bg-green w-14 h-16 sm:w-36 sm:h-40 md:w-30
                                         md:h-30 top-14 left-8 md:top-28 sm:top-28 sm:right-10 md:left-5 lg:left-10 xl:left-20 z-50">
                                        <div className="num-counter center-div w-[96%] h-[96%] bg-dark-green">
                                            <div className="num-counter center-div w-[94%] h-[94%] bg-green">
                                                <div
                                                    className="num-counter center-div flex-col w-[90%] h-[90%] bg-dark-green">
                                                    <h2 className="text-green font-larabie text-xl sm:text-3xl md:text-4xl">
                                                        {currentIndex + 1}
                                                    </h2>
                                                </div>
                                            </div>
                                        </div>
                                    </a.div>
                                    <div className="flex flex-col sm:flex-row items-center justify-center">
                                        <div className="relative w-9/12 sm:11/12 h-60 sm:h-[30rem]">
                                            <a.div style={goalExtra} ref={refGoalExtra}
                                                   className="goal-extra-1 relative bg-green left-[31.5%] top-2 sm:top-4 w-[42%] sm:w-[42%] h-[3.5%] sm:h-[3%] scale-y-[-1]"></a.div>
                                            <div className="relative shape-full">
                                                <div>
                                                    <a.div style={openShowcase} ref={refOpenShowcase}
                                                           className="absolute w-1/4 h-full bg-transparent z-30"></a.div>
                                                    <a.div style={openShowcase__} ref={refOpenShowCase__}
                                                           className="absolute -right-1 w-1/4 h-full bg-transparent z-30"></a.div>
                                                </div>
                                                <div
                                                    className="goal-box bg-green shape-full center-div relative scale-y-[-1] z-20">
                                                    <div
                                                        className="goal-box-2 bg-dark-green flex flex-col justify-center items-center w-[99%] h-[98%]">
                                                        <div className="px-8 sm:px-16 scale-y-[1]">
                                                            <a.div style={slideUpDescription_}
                                                                   ref={refSlideUpDescription_}
                                                                   className="scale-y-[-1]">
                                                                {slowOpDescription.map((props, index) => (
                                                                    <a.span key={index} style={props} ref={refSlowOp}
                                                                            className="description scale-y-[-1] mb-2">
                                                                        {groupedText[index]}{' '}
                                                                    </a.span>
                                                                ))}
                                                            </a.div>
                                                            <a.h2 style={slideUpTitle_} ref={refSlideUpTitle_}
                                                                  className="font-larabie text-base sm:text-3xl md:text-4xl text-2xs text-green scale-y-[-1] pb-3">
                                                                {props.project_goal[currentIndex].title}
                                                            </a.h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <a.div style={goalExtra_} ref={refGoalExtra_}
                                                   className="goal-extra-2 bg-green relative left-[26.2%] bottom-5 sm:bottom-[2.5rem] w-[52%] h-[8%] sm:h-[8%] scale-y-[-1]"></a.div>
                                        </div>
                                        <div className="center-div flex-row mt-10 sm:mt-0 sm:flex-col pl-10">
                                            {props.project_goal.map((goal, index) => (
                                                <div key={index} className={`pagination mr-3 sm:mr-0 sm:mb-6 ${currentIndex === index ? 'bg-green' : 'bg-dark-green'}`}></div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex justify-around sm:justify-center col-divider">
                <div className="flex justify-between w-10/12 sm:w-9/12">
                    <a.div onClick={handlePrevious} style={arrowInward} ref={refArrowInward}
                           className="flex justify-start items-center cursor-pointer">
                        <div className="previous-arrow center-div">
                            <div className="previous-arrow-bg"></div>
                        </div>
                        <h1 className="font-larabie text-sm  md:text-lg lg:text-xl">Previous</h1>
                    </a.div>
                    <a.div onClick={handleNext} style={arrowInward_} ref={refArrowInward_}
                           className="flex justify-start items-center p-2 cursor-pointer">
                        <h2 className="font-larabie text-sm  md:text-lg lg:text-xl">Next</h2>
                        <div className="next-arrow center-div">
                            <div className="next-arrow-bg"></div>
                        </div>
                    </a.div>
                </div>
            </div>
        </div>
    );
}

export default Goal;
