import React, {useState} from 'react';
import {useTrail, config, a, useInView} from '@react-spring/web'

function Challenge(props) {

    const [challengeSolution, setIsChallengeSolution] = useState('challenge')
    const [refScaleIt, scaleIt] = useInView(() => ({
        from: {
            scale: 0,
            opacity: 0,
        },
        to: {
            scale: 1,
            opacity: 1
        },
        config: config.molasses
    }), {rootMargin: '0% 0%', once: true});

    const [refMoveUp, moveUp] = useInView(
        () => ({
            from: {
                y: '-10px',
                opacity: 0,
            },
            to: {
                y: '0px',
                opacity: 1,
            },
            delay: 1000,
            config: config.molasses,
        }),
        { rootMargin: '0% 0%', once: true }
    );


    const [refMoveDown, moveDown] = useInView(
        () => ({
            from: {
                y: '10px',
                opacity: 0,
            },
            to: {
                y: '0px',
                opacity: 1,
            },
            delay: 1000,
            config: config.molasses,
        }),
        { rootMargin: '0% 0%', once: true }
    );

    const [refOpacityAnimation, opacityAnimation] = useInView(
        () => ({
            from: {
                opacity: 0,
            },
            to: {
                opacity: 1,
            },
            delay: 1400,
            config: config.molasses,
        }),
        { rootMargin: '0% 0%', once: true }
    );

    const [refSlideRight, slideRight] = useInView(
        () => ({
            from: {
                x: '-50px',
                opacity: 0,
            },
            to: {
                x: '0px',
                opacity: 1,
            },
            delay: 1200,
            config: config.molasses,
        }),
        { rootMargin: '0% 0%', once: true }
    );

    const [refSlideRight_, slideRight_] = useInView(
        () => ({
            from: {
                x: '-50px',
                transform: 'skew(15deg, 15deg)',
            },
            to: {
                x: '0px',
                transform: 'skew(0deg, 0deg)',
            },
            delay: 1000,
            config: config.molasses,
        }),
        { rootMargin: '0% 0%', once: true }
    );

    const [refSlideLeft, slideLeft] = useInView(
        () => ({
            from: {
                x: '50px',
                opacity: 0,
            },
            to: {
                x: '0px',
                opacity: 1,
            },
            delay: 1200,
            config: config.molasses,
        }),
        { rootMargin: '0% 0%', once: true }
    );


    const [refSlideLeft_, slideLeft_] = useInView(
        () => ({
            from: {
                x: '50px',
                transform: 'skew(-15deg, -15deg)',
            },
            to: {
                x: '0px',
                transform: 'skew(0deg, 0deg)',
            },
            delay: 1000,
            config: config.molasses,
        }),
        { rootMargin: '0% 0%', once: true }
    );

    const [refExpand, expand] = useInView(
        () => ({
            from: { x: '-100px' },
            to: { x: '0px' },
            config: config.molasses,
        }),
        { rootMargin: '0% 0%', once: true }
    );

    const [refExpand_, expand_] = useInView(
        () => ({
            from: { x: '100px' },
            to: { x: '0px' },
            config: config.molasses,
        }),
        { rootMargin: '0% 0%', once: true }
    );

    const [refSlideBorder, slideBorder] = useInView(
        () => ({
            from: { x: '-200px', transform: 'skew(-30deg, -30deg)' },
            to: { x: '0px', transform: 'skew(0deg, 0deg)' },
            config: config.molasses,
            reset: challengeSolution === 'challenge'
        }),
        { rootMargin: '0% 0%', once: true }
    );

    const text = challengeSolution === 'solution' ? props.challenge_solution.solution : props.challenge_solution.challenge;

    const groupSize = 4;

    const textWords = text.split(' ');
    const groupedText = [];
    for (let i = 0; i < textWords.length; i += groupSize) {
        groupedText.push(textWords.slice(i, i + groupSize).join(' '));
    }

    const [refSlowOp, viewSlowOp] = useInView({
        rootMargin: '0% 0%', once: true
    });

    const slowOpDescription = useTrail(groupedText.length, {
        from: {opacity: 0},
        to: {opacity: 1},
        config: {tension: 100, friction: 500, duration: 300},
        reset: viewSlowOp
    });

    const [refGoalExtra, goalExtra] = useInView(
        () => ({
            from: {
                transform: `translateY(100px) scaleY(1)`,
                opacity: 0,
            },
            to: {
                transform: `translateY(0) scaleY(-1)`,
                opacity: 1,
            },
            delay: 500,
            config: config.molasses,
        }),
        { rootMargin: '0% 0%', once: true }
    );

    const [refGoalExtra_, goalExtra_] = useInView(
        () => ({
            from: {
                transform: `translateY(-40px) scaleY(-1)`,
                opacity: 0,
            },
            to: {
                transform: `translateY(0) scaleY(-1)`,
                opacity: 1,
            },
            delay: 500,
            config: config.molasses,
        }),
        {rootMargin: '0% 0%', once: true}
    );

    const handleChallenges = () => {
        setIsChallengeSolution("challenge");
    }

    const handleSolutions = () => {
        setIsChallengeSolution('solution');
    }

    const challengeClass = challengeSolution === 'challenge' ? 'bg-green text-dark-green' : 'bg-dark-green text-green';
    const solutionClass = challengeSolution === 'solution' ? 'bg-green text-dark-green' : 'bg-dark-green text-green';

    return (
        <div>
            <div className="flex justify-center">
                <div className="w-11/12 mb-14">
                    <div className="flex justify-center gap-2 sm:gap-4">
                        <div className="inline-flex justify-around items-center">
                            <a.div style={slideRight} ref={refSlideRight} className="challenge-horizontal-line relative lg:left-2"></a.div>
                            <a.div style={slideRight_} ref={refSlideRight_} className="challenge-vertical-line relative lg:left-2"></a.div>
                            <a.div style={opacityAnimation} ref={refOpacityAnimation} className="challenge-open-brace"></a.div>
                            <a.div onClick={handleChallenges} style={scaleIt} ref={refScaleIt} className={`cursor-pointer center-div challenge-solution-bg ${challengeClass}`}>
                                <a.h1 style={moveUp} ref={refMoveUp} className="challenge-solution-text">Challenges
                                </a.h1>
                            </a.div>
                            <a.div style={opacityAnimation} ref={refOpacityAnimation} className="challenge-close-brace"></a.div>
                            <a.div style={expand_} ref={refExpand_} className="challenge-vertical-line relative lg:right-2"></a.div>
                            <a.div style={expand_} ref={refExpand_} className="challenge-horizontal-line relative lg:right-2"></a.div>
                        </div>
                        <div className="inline-flex justify-around items-center">
                            <a.div style={expand} ref={refExpand} className="challenge-horizontal-line relative lg:left-2"></a.div>
                            <a.div style={expand} ref={refExpand} className="challenge-vertical-line relative lg:left-2"></a.div>
                            <a.div style={opacityAnimation} ref={refOpacityAnimation} className="challenge-open-brace"></a.div>
                            <a.div onClick={handleSolutions} style={scaleIt} className={`cursor-pointer center-div challenge-solution-bg ${solutionClass}`}>
                                <a.h2 style={moveDown} ref={refMoveDown} className="challenge-solution-text">Solution</a.h2>
                            </a.div>
                            <a.div style={opacityAnimation} ref={refOpacityAnimation} className="challenge-close-brace"></a.div>
                            <a.div style={slideLeft_} ref={refSlideLeft_} className="challenge-vertical-line relative lg:right-2"></a.div>
                            <a.div style={slideLeft} ref={refSlideLeft} className="challenge-horizontal-line relative lg:right-2"></a.div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex justify-around col-divider">
                <div className="w-11/12">
                    <div className="flex justify-around w-full">
                        <div className="center-div flex-col md:items-stretch md:flex-row md:justify-around">
                            <div className="flex flex-row items-center justify-center">
                                <div className="relative w-10/12 sm:11/12 h-60 sm:h-[30rem]">
                                    <a.div style={goalExtra} ref={refGoalExtra}
                                        className="goal-extra-1 relative bg-green left-[31.5%] top-2 sm:top-4 w-[42%] sm:w-[42%] h-[3.5%] sm:h-[3%] scale-y-[-1]"></a.div>
                                    <div className="goal-box bg-green shape-full center-div scale-y-[-1] relative z-20">
                                        <div
                                            className="goal-box-2 bg-dark-green flex flex-col justify-center items-center w-[99%] h-[98%]">
                                            <div className="px-8 flex justify-start">
                                                <a.div style={slideBorder} ref={refSlideBorder}
                                                    className="border-r-8 border-green py-4 w-11/12 sm:w-64 bg-gradient-to-l from-single-gradient"></a.div>
                                                <div className="text-1xs sm:text-sm md:text-lg lg:text-2xl scale-y-[-1] sm:ml-8 mb-2">
                                                    {slowOpDescription.map((props, index) => (
                                                        <a.span key={index} style={props} ref={refSlowOp}>
                                                            {groupedText[index]}{' '}
                                                        </a.span>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <a.div style={goalExtra_} ref={refGoalExtra_}
                                           className="goal-extra-2 bg-green relative left-[26.2%] bottom-5 sm:bottom-[2.5rem] w-[52%] h-[8%] sm:h-[8%] scale-y-[-1]"></a.div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Challenge;
