import data from "../../assets/data/data.json";
import React from 'react';
import '../../styles/App.css';
import {a, config, useSprings, useInView} from '@react-spring/web'
import Arrow from './arrow' ;
import Intro from './intro' ;
import LangUsed from "./lang_used";
import Goal from "./goal";
import VisualIdentification from "./visual_identification";
import Challenge from "./challenge";
import Wireframe from "./wireframe";
import {useParams} from "react-router-dom";
import {useNavigate} from "react-router-dom";

const delayValues = [0.1, 0, 0, 0.1]

const Portfolio = ({props}) => {
    const navigate = useNavigate();
    const {id} = useParams();
    const privacy = data.privacy_link;
    const terms = data.terms_link;
    const portfolio = data.portfolio;
    const currentIndex = Number(id)
    const {
        image,
        project,
        link, description,
        language, project_goal,
        visual_identification,
        challenge_solution,
        wireframe,
    } = portfolio[id]


    const scrollToTop = () => {
       return window.scrollTo({
           top: 0,
           left: 0,
           right: 0,
           behavior: 'smooth'
       }); // Scrolls to the top left corner of the page
    };

    const [refWiden, widen] = useInView(
        () => ({
            from: {
                transform: 'scale(0)',
            },
            to: {
                transform: 'scale(1)',
            },
            delay: 200,
            config: config.molasses,
        }),
        {rootMargin: '0% 0%', once: true}
    );

    const [refSlideUpFooter, slideUpFooter] = useInView(
        () => ({
            from: {y: '100px', opacity: 0},
            to: {y: '0px', opacity: 1},
            delay: 200,
            config: config.molasses,
        }),
        {rootMargin: '0% 0%', once: true}
    );

    const commonSlideRight = {
        from: {x: '-100px'},
        to: {x: '0px'},
        config: config.molasses
    };

    const commonSlideLeft = {
        from: {x: '105px'},
        to: {x: '0px'},
        config: config.molasses
    };
    const menus = [
        {text: '<Home/>', route: 'home', config: commonSlideRight},
        {text: '<Portfolio/>', route: 'portfolio', config: commonSlideRight},
        {text: '<Blog/>', route: 'blog', config: commonSlideLeft},
        {text: '<Contact/>', route: 'contact', config: commonSlideLeft},
    ];

    const [ref, isInViewSpring] = useInView({
        rootMargin: '0% 0%',
        once: true
    })

    const menuSprings = useSprings(
        menus.length,
        menus.map((menu, index) => ({
            ...menus[index].config,
            delay: delayValues[index] * 1000,
            reset: isInViewSpring
        }))
    );

    const [refSlideIn, slideIn] = useInView(
        () => ({
            from: {
                x: '-100px',
                opacity: 0,
            },
            to: {
                x: '0px',
                opacity: 1,
            },
            delay: 200,
            config: config.molasses,
        }),
        {rootMargin: '0% 0%', once: true}
    );

    const [refSlideIn_, slideIn_] = useInView(
        () => ({
            from: {
                x: '100px',
                opacity: 0,
            },
            to: {
                x: '0px',
                opacity: 1,
            },
            delay: 200,
            config: config.molasses,
        }),
        {rootMargin: '0% 0%', once: true}
    );

    const handlePrevious = () => {
        navigate(`/portfolio/${(currentIndex - 1 + portfolio.length) % portfolio.length}`);
        scrollToTop();
    };
    const handleNext = () => {
        navigate(`/portfolio/${(currentIndex + 1) % portfolio.length}`);
        scrollToTop();
    };

    const handleClick = (link) => {
        window.location.href = link;
    };

    //redirection to home route
    const routeToHome = () => {
        navigate('/');
    }

    // for intro component
    return (
        <div className="2xl:mx-44 lg:mx-24 md:mx-0 pt-4 md:pt-12 font-callingCode bg-facade text-white relative z-50">
            <Arrow></Arrow>
            <Intro {...{image, project, link, description}}></Intro>
            <LangUsed {...{language}}></LangUsed>
            <Goal {...{project_goal}}></Goal>
            <VisualIdentification {...{visual_identification}}></VisualIdentification>
            <Challenge {...{challenge_solution}}></Challenge>
            <Wireframe {...{description, wireframe, link}}></Wireframe>
            <div className="flex justify-around mb-24">
                <a.div className="w-full sm:w-[84.5%]">
                    <div className="flex justify-between w-full">
                        <a.div onClick={handlePrevious} style={slideIn_} ref={refSlideIn_}
                               className="flex justify-start items-center p-2 cursor-pointer">
                            <div className="previous-arrow center-div">
                                <div className="previous-arrow-bg"></div>
                            </div>
                            <h1 className="font-larabie text-xs md:text-lg lg:text-xl">Previous
                                Project</h1>
                        </a.div>
                        <a.div onClick={handleNext} style={slideIn} ref={refSlideIn}
                               className="flex justify-end items-center cursor-pointer">
                            <h2 className="font-larabie text-xs md:text-lg lg:text-xl">Next
                                Project</h2>
                            <div className="next-arrow center-div">
                                <div className="next-arrow-bg"></div>
                            </div>
                        </a.div>
                    </div>
                </a.div>
            </div>
            <div className="flex justify-around">
                <div className="w-full sm:w-4/5">
                    <a.div style={widen} ref={refWiden} className="custom-border-header border-bottom-8 w-full"></a.div>
                    <div className="flex justify-around">
                        <div className="flex justify-evenly py-6 text-xs sm:text-base lg:text-lg w-11/12">
                            {menuSprings.map((style, index) => (
                                <a.div key={index} style={style} ref={ref} className="cursor-pointer" onClick={() => {routeToHome()}}>
                                    {menus[index].text}
                                </a.div>
                            ))}
                        </div>
                    </div>
                    <a.div style={widen} ref={refWiden} className="custom-border-header border-bottom-8 w-full"></a.div>
                </div>
            </div>
            <div className="flex justify-around flex-row h-28 items-center bg-gradient-to-t from-dark-green">
                <div className="w-10/12 sm:w-full ">
                    <a.div style={slideUpFooter} ref={refSlideUpFooter}
                           className="flex justify-around sm:justify-evenly text-xs sm:text-base lg:text-lg flex-row">
                        <div className="cursor-pointer">{new Date().getFullYear()} All copyright reserved</div>
                        <div className="cursor-pointer" onClick={() => handleClick(terms)}>Terms and conditions</div>
                        <div className="cursor-pointer" onClick={() => handleClick(privacy)}>Privacy and policy</div>
                    </a.div>
                </div>
            </div>
        </div>
    );
};
export default Portfolio;
